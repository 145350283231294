<template>
  <b-card class="mb-2" no-body>
    <div slot="header" @click="collapseCategory()" style="cursor: pointer">
      <b-row>
        <b-col md="7" lg="8">
          <h5 class="mb-0">
            <div>
              <div class="pull-left d-inline" style="min-width: 28px">{{ index + 1 }}.</div>
              <div class="d-inline">
                <p class="d-inline" style="padding-right: 1px" v-b-popover.hover.html="getFactorDescription" title="Verkþættir flokks">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </h5>
        </b-col>
        <b-col md="5" lg="4">
          <div class="pull-right h5 mb-0 d-inline-block">
            <i class="fa fa-fw" :class="{ 'fa-chevron-down': collapse, 'fa-chevron-right': !collapse }"></i>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="collapse" v-model="collapse">
      <div class="card-body">
        <strong class="pb-2" style="font-size: 15px">Verkþættir: </strong>
        <factor-list :id="id" :category="item.id" :factorOverview="factorOverview" :competenceName="competenceName"></factor-list>
      </div>
    </b-collapse>
  </b-card>
</template>
<script>
import FactorList from '@/components/login/version/FactorList.vue';

export default {
  name: 'careerbook-factor-category-item',
  components: {
    FactorList,
  },
  props: ['item', 'notCollapsed', 'id', 'professionId', 'index', 'competenceName'],
  data() {
    return {
      collapse: false,
      factorOverview: null,
    };
  },
  methods: {
    // Sækir verkþætti fyrir tooltip
    getFactorDescription() {
      let html = '<ul>';
      this.item.factors.forEach((c) => {
        html += `<li>${c.name}</li>`;
      });
      html += '</ul>';

      if (this.item.factors.length === 0) {
        html = '<i>Engir tengdir</i>';
      }
      return html;
    },
    collapseCategory() {
      if (this.edit !== true) {
        this.collapse = !this.collapse;
        // this.careerbookCategoryNotificationUpdate();
        if (!this.collapse) {
          this.editFactors = false;
        }
      }
    },
  },
  created() {
    if (this.notCollapsed) {
      this.collapseCategory();
    }
  },
};
</script>
