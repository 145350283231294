<template>
  <div>
    <navbar></navbar>
    <b-container>
      <slot></slot>
    </b-container>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue';

export default {
  name: 'page',
  components: {
    Navbar,
  },
};
</script>
