<template>
  <div>
    <b-modal
      id="applicationFileAdd"
      title="Bæta við gögnum"
      size="md"
      lazy
      :no-close-on-backdrop="true"
      ref="viewModal"
      :ok-only="true"
      :ok-disabled="!uploaded"
      @ok="successful"
    >
      <supervisor-data
        v-for="(supervisor, index) in supervisor"
        :key="index"
        :supervisor="supervisor"
        :supervisorId="supervisor.id"
        :applicationId="applicationId"
        @has-file="onFileListUpdated"
        @uploaded-files="filesUploaded"
      >
      </supervisor-data>
    </b-modal>
  </div>
</template>

<script>
import SupervisorData from '@/components/application/SupervisorData.vue';

export default {
  name: 'application-file-add',
  components: {
    SupervisorData,
  },
  data() {
    return {
      loading: false,
      uploaded: false,
      supervisor: null,
      applicationId: null,
    };
  },
  methods: {
    show(supervisor = null, applicationId = null) {
      this.supervisor = supervisor;
      this.applicationId = applicationId;
      this.$refs.viewModal.show();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.viewModal.hide();
    },
    onFileListUpdated(hasFiles) {
      this.$emit('has-file', hasFiles);
    },
    filesUploaded(files) {
      this.$emit('uploaded-files', files);
      this.uploaded = true;
    },
  },
  created() {},
};
</script>
