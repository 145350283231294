<template>
  <page-login>
    <br />
    <b-card>
      <b-row>
        <b-col>
          <h5 class="my-4">Yfirlit yfir vinnustaði</h5>
        </b-col>
      </b-row>

      <div>
        <table class="table" style="table-layout: fixed">
          <tr>
            <th style="padding: 10px 0px">
              <b-form-input type="text" v-model="searchName" placeholder="Leita eftir heiti eða kennitölu"></b-form-input>
            </th>
            <th style="padding: 10px">
              <v-select
                class="select-style"
                v-model="searchZipCode"
                :options="zipCodeList"
                multiple
                placeholder="Veldu póstnúmer"
              ></v-select>
            </th>
            <th style="padding: 10px 0px">
              <v-select
                class="select-style"
                v-model="searchProfession"
                :options="professionList"
                multiple
                label="name"
                placeholder="Veldu starfsgrein"
              ></v-select>
            </th>
          </tr>
        </table>
        <table class="table table-hover">
          <tr>
            <th>Heiti</th>
            <th>Kennitala</th>
            <th>Póstnúmer</th>
            <th>Starfsgreinar</th>
            <th>Fjöldi plássa</th>
          </tr>
          <tbody>
            <tr v-if="!loading.workplace && workplaceList.length == 0">
              <td colspan="3">
                <i>Engar leitarniðurstöður fundust.</i>
              </td>
            </tr>
            <tr v-if="loading.workplace">
              <td colspan="3">
                <i class="fa fa-fw fa-spin fa-spinner text-center"></i>
              </td>
            </tr>
            <tr v-for="(workplace, index) in workplaceList" :key="index">
              <td>{{ workplace.name }}</td>
              <td>{{ workplace.ssn | formatSsn }}</td>
              <td>{{ workplace.zip_code }}</td>
              <td>{{ workplace.professions.map((p) => p.name).join(', ') }}</td>
              <td>{{ workplace.slots }}</td>
            </tr>
          </tbody>
        </table>
        <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
      </div>
    </b-card>
  </page-login>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import Pagination from '@/components/layout/Pagination.vue';

import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'workplace-list',
  mixins: [paginationMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      loading: {
        workplace: false,
        profession: false,
      },
      page: null,
      pagination: null,
      workplaceList: [],
      professionList: [],
      zipCodeList: [],
      searchProfession: [],
      searchName: '',
      searchZipCode: [],
    };
  },
  filters: {
    formatSsn(string) {
      return `${string.substring(0, 6)}-${string.substring(6, 10)}`;
    },
  },
  methods: {
    /*
     * Sækja yfirlit yfir vinnustaði
     */
    async loadList(page = null, limit = null) {
      this.loading.workplace = true;
      this.pagination = null;
      this.workplaceList = [];
      try {
        const response = await core.workplaceList(
          page,
          limit,
          this.searchProfession.map((it) => it.uuid),
          this.searchName,
          this.searchZipCode,
        );
        this.workplaceList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response?.status ?? -1}) kom upp við að sækja yfirlit yfir vinnustaði. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading.workplace = false;
      }
    },
    async loadProfessions() {
      const { data } = await core.workplaceProfessionsList();
      this.professionList = data.items;
    },
    async loadZipCodes() {
      const { data } = await core.workplaceZipCodeList();
      this.zipCodeList = data.items.map((it) => it.zip_code);
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchProfession() {
      this.loadList();
    },
    searchName() {
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    searchZipCode() {
      this.loadList();
    },
  },
  created() {
    this.loadList();
    this.loadProfessions();
    this.loadZipCodes();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
<style lang="scss">
.select-style {
  --vs-search-input-placeholder-color: black;
  --vs-font-size: 1em;
  .vs__dropdown-toggle {
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0;
    border-color: #ced4da;
  }
}
</style>
