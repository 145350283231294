<template>
  <page>
    <div class="container">
      <div class="card mt-3">
        <b-card
          header="Skráðu upplýsingar um fyrirtækið"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          class="[mt-3, validation]"
        >
          <!-- Here starts  the inputs for company info-->
          <div class="companyInfoItems" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center">
            <b-form-group class="required" label-for="nationalId" label="Kennitala:" style="width: 200px; padding: 10px">
              <b-form-input
                id="nationalId"
                name="nationalId"
                type="text"
                placeholder="Kennitala fyrirtækis"
                v-model="$v.companyInfoNationalId.$model"
                :state="$v.companyInfoNationalId.$dirty ? !$v.companyInfoNationalId.$error : null"
                oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                maxlength="11"
                @input="handleCompanyNationalIdInput"
                @focus="textTouched = true"
                required
              >
              </b-form-input>
              <div v-if="$v.companyInfoNationalId.$error">
                <div style="color: indianred">Kennitala ekki gilt</div>
              </div>
              <div v-else>&nbsp;</div>
            </b-form-group>
            <b-form-group class="required" label-for="name" label="Nafn:" style="width: 400px; padding: 10px">
              <b-form-input
                id="name"
                name="name"
                type="text"
                placeholder="Nafn fyrirtækis"
                v-model="$v.companyInfoName.$model"
                :state="$v.companyInfoName.$dirty ? !$v.companyInfoName.$error : null"
                @focus="textTouched = true"
                required
              >
              </b-form-input>
              <div v-if="$v.companyInfoName.$error">
                <div style="color: indianred">Nafn fyrirtækis ekki gilt</div>
              </div>
              <div v-else>&nbsp;</div>
            </b-form-group>
            <b-form-group class="required" label-for="tel" label="Símanúmer:" style="width: 200px; padding: 10px">
              <b-form-input
                id="tel"
                name="tel"
                type="text"
                placeholder="Símanúmer fyrirtækis"
                v-model="$v.companyInfoPhone.$model"
                :state="$v.companyInfoPhone.$dirty ? !$v.companyInfoPhone.$error : null"
                oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                maxlength="8"
                @input="handleCompanyPhoneNumberInput"
                required
              >
              </b-form-input>
              <div v-if="$v.companyInfoPhone.$error">
                <div style="color: indianred">Símanúmer ekki gilt</div>
              </div>
              <div v-else>&nbsp;</div>
            </b-form-group>
            <b-form-group class="required" label-for="email" label="Netfang:" style="width: 400px; padding: 10px">
              <b-form-input
                id="email"
                name="email"
                type="email"
                placeholder="Netfang fyrirtækis"
                v-model="$v.companyInfoEmail.$model"
                :state="$v.companyInfoEmail.$dirty ? !$v.companyInfoEmail.$error : null"
                required
              />
              <div v-if="$v.companyInfoEmail.$error">
                <div style="color: indianred">Netfang ekki gilt</div>
              </div>
              <div v-else>&nbsp;</div>
            </b-form-group>
            <b-form-group label-for="address" label="Heimilisfang:" style="width: 200px; padding: 10px">
              <b-form-input
                id="address"
                name="address"
                type="text"
                placeholder="Heimilsfang"
                v-model="$v.companyInfoAddress.$model"
                :state="$v.companyInfoAddress.$dirty ? !$v.companyInfoAddress.$error : null"
                required
              >
              </b-form-input>
              <div v-if="$v.companyInfoAddress.$error">
                <div style="color: indianred">Heimilisfang ekki gilt</div>
              </div>
              <div v-else>&nbsp;</div>
            </b-form-group>
            <b-form-group label-for="zipcode" label="Póstnúmer:" style="width: 200px; padding: 10px">
              <b-form-input
                id="zipcode"
                name="zipcode"
                placeholder="Póstnúmer"
                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                maxlength="3"
                v-model="$v.companyInfoAreaCode.$model"
                :state="$v.companyInfoAreaCode.$dirty ? !$v.companyInfoAreaCode.$error : null"
                required
              >
              </b-form-input>
              <div v-if="$v.companyInfoAreaCode.$error">
                <div style="color: indianred">Póstnúmer ekki gilt</div>
              </div>
              <div v-else>&nbsp;</div>
            </b-form-group>
          </div>
          <!-- Here stops the inputs for company info-->
          <!-- Below starts the user to choose the "starfsgrein" -->
        </b-card>
        <b-card
          header="Skráðu upplýsingar um starfsgrein og tilsjónaraðila"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          class="mt-3"
        >
          <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center">
            <b-form-group label="" description="" label-for="professions">
              <v-select
                multiple
                :placeholder="form.application.professions.length > 0 ? 'Bæta við starfsgrein' : 'Veldu starfsgrein'"
                @input="professionsChanged"
                :debounce="250"
                style="width: 400px"
                v-model="selected"
                :options="options"
                :state="submitted && errors.has('profession') ? false : null"
              >
                <template slot="no-options">Engar fleiri starfsgreinar</template>
              </v-select>
            </b-form-group>
          </div>
          <!-- Here ends the dropdown to select "Starfsgrein" -->
          <!-- Starfsferilsumsóknar template -->
          <div v-for="(card, index) in cards" :key="index" :class="{ 'new-card': card.isNew }">
            <b-card class="mt-3">
              <template #header>
                <div
                  class="button"
                  style="display: flex; flex-wrap: wrap; justify-content: space-between; align-content: center; align-items: center"
                >
                  <h5 class="mb-0" style="font-weight: bold">{{ card.tableName }}</h5>
                  <b-button @click="removeCard(index, card.tableName)"><i class="fa fa-fw fa-trash"></i></b-button>
                </div>
              </template>
              <b-form-group class="required" label-for="nationalId" label="Laus pláss í starfsnámi:" style="width: 200px; padding: 10px">
                <b-form-input
                  id="input"
                  name="nationalId"
                  type="number"
                  v-model="cards[index].slots"
                  :state="$v.availablePositions.$dirty ? !$v.availablePositions.$error : null"
                  required
                >
                </b-form-input>
                <div v-if="$v.availablePositions.$error">
                  <div v-if="!$v.availablePositions.required">Vantar fjölda í starfsnámi</div>
                  <div v-if="!$v.availablePositions.minValue">lámarks fjöldi er 1</div>
                </div>
                <div v-else style="color: #ffffff">&nbsp;</div>
              </b-form-group>
              <b-card style="display: flex; flex-wrap: wrap; align-items: left; justify-content: left">
                <template #header>
                  <div class="mb-0" style="display: flex; align-items: left">
                    <h6 class="mb-0">Veldu verkþætti</h6>
                    <h6 class="mb-0 ml-2"><a class="mb-0" href="/utgafur" target="_blank">(Yfirlit á útgáfu verkþátta)</a></h6>
                  </div>
                </template>
                <b-form-checkbox v-model="cards[index].selectAllCheckBoxes" @change="toggleSelectAllFactors(card, index)">Velja allt</b-form-checkbox>
                <br />
                <div v-if="checkedValidations && !cards[index].professionFactorSelected" class="invalid-input">
                  Það þarf að velja að minnsta kosti einn verkþátt
                </div>
                <b-table
                  id="checkboxTable"
                  :key="checkBoxTableKey"
                  small
                  striped
                  hover
                  :items="cards[index].factorList"
                  :fields="checkboxItemsColumn"
                  header="Verkþættir"
                >
                  <template #cell(checkbox)="data">
                    <b-form-checkbox v-model="data.item.selected" required></b-form-checkbox>
                  </template>
                </b-table>
              </b-card>
              <b-card header="Ábyrgðaraðili með meistarabréf" header-class="h6">
                <b-table small :items="cards[index].supervisorDataMaster" :fields="supervisorColumnMaster">
                  <template #cell(nationalId)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.nationalId === false && 'invalid-input'"
                      placeholder="Kennitala"
                      required
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="11"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, masterRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.nationalId === false">Kennitala er ekki gilt</label>
                  </template>
                  <template #cell(name)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.name === false && 'invalid-input'"
                      placeholder="Nafn"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, masterRoleString)"
                      disabled
                      required
                    />
                    <label class="invalid-label" v-if="data.item.validate.name === false">Nafn er ekki gilt</label>
                  </template>
                  <template #cell(phone)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.phone === false && 'invalid-input'"
                      placeholder="Símanúmer"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="8"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, masterRoleString)"
                      required
                    />
                    <label class="invalid-label" v-if="data.item.validate.phone === false">Símanúmer er ekki gilt</label>
                  </template>
                  <template #cell(email)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.email === false && 'invalid-input'"
                      placeholder="Netfang"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, masterRoleString)"
                      required
                    />
                    <label class="invalid-label" v-if="data.item.validate.email === false">Netfang er ekki gilt</label>
                  </template>
                  <template #cell(addFile)="data">
                    <b-button
                      style="width: 100%; height: 100%"
                      class="btn btn-sm"
                      :class="{ 'btn-success': fileUploaded(data.item) }"
                      :disabled="addFileLocked(data.item) || fileUploaded(data.item)"
                      title="Bæta við gögnum"
                      @click.prevent="applicationFileAdd(masterRoleString, index, data.index)"
                      v-b-tooltip.hover
                    >
                      <i class="fa fa-fw fa-lock" v-if="addFileLocked(data.item)"></i>
                      <i class="fa fa-fw fa-check" v-else-if="fileUploaded(data.item)"></i>
                      <i class="fa fa-fw fa-plus-circle" v-else></i>
                    </b-button>
                  </template>
                </b-table>
              </b-card>
              <b-card header="Tengiliðaupplýsingar tilsjónaraðila" header-class="h6">
                <h6 style="text-decoration: underline">Tilsjónaraðili með meistarabréf</h6>
                <b-table
                  v-if="cards[index].supervisorDataExtraMaster.length > 0"
                  small
                  :items="cards[index].supervisorDataExtraMaster"
                  :fields="supervisorColumnExtraMaster"
                  header="Tilsjónaraðili með meistarabréf"
                >
                  <template #cell(nationalId)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.nationalId === false && 'invalid-input'"
                      placeholder="Kennitala"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="11"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, extraMasterRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.nationalId === false">Kennitala er ekki gilt</label>
                  </template>
                  <template #cell(name)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.name === false && 'invalid-input'"
                      placeholder="Nafn"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, extraMasterRoleString)"
                      disabled
                    />
                    <label class="invalid-label" v-if="data.item.validate.name === false">Nafn er ekki gilt</label>
                  </template>
                  <template #cell(phone)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.phone === false && 'invalid-input'"
                      placeholder="Símanúmer"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="8"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, extraMasterRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.phone === false">Símanúmer er ekki gilt</label>
                  </template>
                  <template #cell(email)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.email === false && 'invalid-input'"
                      placeholder="Netfang"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, extraMasterRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.email === false">Netfang er ekki gilt</label>
                  </template>
                  <template #cell(addFile)="data">
                    <b-button
                      style="width: 100%; height: 100%"
                      class="btn btn-sm"
                      :class="{ 'btn-success': fileUploaded(data.item) }"
                      :disabled="addFileLocked(data.item) || fileUploaded(data.item)"
                      title="Bæta við gögnum"
                      @click.prevent="applicationFileAdd(extraMasterRoleString, index, data.index)"
                      v-b-tooltip.hover
                    >
                      <i class="fa fa-fw fa-lock" v-if="addFileLocked(data.item)"></i>
                      <i class="fa fa-fw fa-check" v-else-if="fileUploaded(data.item)"></i>
                      <i class="fa fa-fw fa-plus-circle" v-else></i>
                    </b-button>
                  </template>
                  <template #cell(removeRow)="data">
                    <b-button class="btn btn-sm" @click="removeRowExtraMaster(data.item.key, index)"
                      ><i class="fa fa-fw fa-trash"></i
                    ></b-button>
                  </template>
                </b-table>
                <div style="padding-bottom: 10px; display: flex; justify-content: flex-end">
                  <b-button @click="addRowTableExtraMaster(index)">Bæta við</b-button>
                </div>
                <h6 style="text-decoration: underline">Tilsjónaraðili með sveinsbréf</h6>
                <b-table
                  v-if="cards[index].supervisorDataJourneyman.length > 0"
                  small
                  :items="cards[index].supervisorDataJourneyman"
                  :fields="supervisorColumnJourneyman"
                  header="Tilsjónaraðili með sveinsbréf"
                >
                  <template #cell(nationalId)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.nationalId === false && 'invalid-input'"
                      placeholder="Kennitala"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="11"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, journeymanRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.nationalId === false">Kennitala er ekki gilt</label>
                  </template>
                  <template #cell(name)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.name === false && 'invalid-input'"
                      placeholder="Nafn"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, journeymanRoleString)"
                      disabled
                    />
                    <label class="invalid-label" v-if="data.item.validate.name === false">Nafn er ekki gilt</label>
                  </template>
                  <template #cell(phone)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.phone === false && 'invalid-input'"
                      placeholder="Símanúmer"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="8"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, journeymanRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.phone === false">Símanúmer er ekki gilt</label>
                  </template>
                  <template #cell(email)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.email === false && 'invalid-input'"
                      placeholder="Netfang"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, journeymanRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.email === false">Netfang er ekki gilt</label>
                  </template>
                  <template #cell(addFile)="data">
                    <b-button
                      style="width: 100%; height: 100%"
                      class="btn btn-sm"
                      :class="{ 'btn-success': fileUploaded(data.item) }"
                      :disabled="addFileLocked(data.item) || fileUploaded(data.item)"
                      title="Bæta við gögnum"
                      @click.prevent="applicationFileAdd(journeymanRoleString, index, data.index)"
                      v-b-tooltip.hover
                    >
                      <i class="fa fa-fw fa-lock" v-if="addFileLocked(data.item)"></i>
                      <i class="fa fa-fw fa-check" v-else-if="fileUploaded(data.item)"></i>
                      <i class="fa fa-fw fa-plus-circle" v-else></i>
                    </b-button>
                  </template>
                  <template #cell(removeRow)="data">
                    <b-button class="btn btn-sm" @click="removeRowJourneyman(data.item.key, index)"
                      ><i class="fa fa-fw fa-trash"></i
                    ></b-button>
                  </template>
                </b-table>
                <div style="padding-bottom: 10px; display: flex; justify-content: flex-end">
                  <b-button @click="addRowTableJourneyman(index)">Bæta við</b-button>
                </div>
                <h6 style="text-decoration: underline">Eftirlitsaðili með lesaðgang</h6>
                <b-table
                  v-if="cards[index].supervisorDataMonitor.length > 0"
                  small
                  :items="cards[index].supervisorDataMonitor"
                  :fields="supervisorColumnMonitor"
                  header="Eftirlitsaðili með lesaðgang"
                >
                  <template #cell(nationalId)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.nationalId === false && 'invalid-input'"
                      placeholder="Kennitala"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="11"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, monitorRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.nationalId === false">Kennitala er ekki gilt</label>
                  </template>
                  <template #cell(name)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.name === false && 'invalid-input'"
                      placeholder="Nafn"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, monitorRoleString)"
                      disabled
                    />
                    <label class="invalid-label" v-if="data.item.validate.name === false">Nafn er ekki gilt</label>
                  </template>
                  <template #cell(phone)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.phone === false && 'invalid-input'"
                      placeholder="Símanúmer"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="8"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, monitorRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.phone === false">Símanúmer er ekki gilt</label>
                  </template>
                  <template #cell(email)="data">
                    <b-form-input
                      v-model="data.value"
                      :class="data.item.validate.email === false && 'invalid-input'"
                      placeholder="Netfang"
                      @input="updateCard(data.item.key, data.field.key, data.value, index, monitorRoleString)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.email === false">Netfang er ekki gilt</label>
                  </template>
                  <template #cell(addFile)="data">
                    <b-button
                      style="width: 100%; height: 100%"
                      class="btn btn-sm"
                      :class="{ 'btn-success': fileUploaded(data.item) }"
                      :disabled="addFileLocked(data.item) || fileUploaded(data.item)"
                      title="Bæta við gögnum"
                      @click.prevent="applicationFileAdd(monitorRoleString, index, data.index)"
                      v-b-tooltip.hover
                    >
                      <i class="fa fa-fw fa-lock" v-if="addFileLocked(data.item)"></i>
                      <i class="fa fa-fw fa-check" v-else-if="fileUploaded(data.item)"></i>
                      <i class="fa fa-fw fa-plus-circle" v-else></i>
                    </b-button>
                  </template>
                  <template #cell(removeRow)="data">
                    <b-button class="btn btn-sm" @click="removeRowMonitor(data.item.key, index)"
                      ><i class="fa fa-fw fa-trash"></i
                    ></b-button>
                  </template>
                </b-table>
                <div style="padding-bottom: 10px; display: flex; justify-content: flex-end">
                  <b-button @click="addRowTableMonitor(index)">Bæta við</b-button>
                </div>
              </b-card>
            </b-card>
            <!-- Herna enda starfsferils templateinn-->
          </div>
        </b-card>
        <!--Staðfestu skilmála byrjar-->
        <b-card
          header="Staðfestu skilmála"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          header-tag="h5"
          class="mt-3"
        >
          <b-card
            class="mt-3"
            style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center"
            :class="this.checkedValidations && !this.termsAndConditions && 'invalid-input'"
          >
            <b-form-checkbox v-model="termsAndConditions">
              <strong>
                Með umsókn er staðfest að iðnmeistari/fyrirtæki/stofnun uppfylli skilyrði reglugerðar nr.
                <a href="https://island.is/reglugerdir/nr/0180-2021" target="_blank">180/2021</a>.
              </strong>
            </b-form-checkbox>
          </b-card>
        </b-card>
        <!--Staðfestu skilmála endar-->
        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; padding: 10px">
          <b-btn style="width: 200px; padding: 10px; background-color: rgb(2, 56, 96)" href="/">Hætta við umsókn</b-btn>
          <b-btn style="width: 200px; padding: 10px; background-color: rgb(73, 166, 0)" @click="validateBeforeSubmit">
            Senda inn umsókn
          </b-btn>
        </div>
      </div>
    </div>
    <application-file-modal ref="applicationFileAdd" @uploaded-files="fileAddSuccess" />
  </page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import application from '@/api/application';
import core from '@/api/core';
import myPage from '@/api/myPage';
import ApplicationFileModal from '@/components/application/Modal/ApplicationFileModal.vue';

import { required, minLength, minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { randomId, roleMap, masterRoleString, journeymanRoleString, monitorRoleString, extraMasterRoleString } from '@/utils';
import {
  validateNationalId,
  validatePhoneNumber,
  validateEmail,
  validateAreaCode,
  validateStringMinMaxLength,
  fetchNameFromNationalRegistry,
  fetchInfoFromNationalRegistry,
} from '@/utils/validators';
import { formatNationalId, formatPhoneNumber, removeHyphen } from '@/utils/formatters';

const formattedNationalIdLength = 11;

export default {
  mixins: [validationMixin],
  name: 'application-create-form',
  components: {
    ApplicationFileModal,
  },
  data() {
    return {
      roleMap,
      masterRoleString,
      journeymanRoleString,
      monitorRoleString,
      extraMasterRoleString,
      cards: [],
      isEditing: false,
      applicationId: 0,
      loading: false,
      submitted: false,
      professionList: [],
      selected: [],
      selectedFactors: [],
      hasFiles: [],
      tableIdCounter: 0,
      availablePositions: 1,
      checkBoxTableKey: 0,
      form: {
        application: {
          name: '',
          ssn: '',
          address: '',
          zipCode: '',
          email: '',
          mobile: '',
          professions: [],
          supervisors: [],
          factors: [],
        },
      },
      checkboxItemsColumn: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'title', label: 'Verkþáttur' },
      ],
      supervisorColumnMaster: [
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Netfang' },
        { key: 'addFile', label: 'Meistarabréf' },
      ],
      supervisorColumnExtraMaster: [
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Netfang' },
        { key: 'addFile', label: 'Meistarabréf' },
        { key: 'removeRow', label: 'Fjarlægja' },
      ],
      supervisorColumnJourneyman: [
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Netfang' },
        { key: 'addFile', label: 'Sveinsbréf' },
        { key: 'removeRow', label: 'Fjarlægja' },
      ],
      supervisorColumnMonitor: [
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Netfang' },
        { key: 'addFile', label: 'Gögn' },
        { key: 'removeRow', label: 'Fjarlægja' },
      ],
      supervisorsCollected: [],
      companyNationalIdVerified: false,
      companyInfoNationalId: null,
      companyInfoName: '',
      companyInfoPhone: null,
      companyInfoEmail: '',
      companyInfoAddress: '',
      companyInfoAreaCode: '',
      termsAndConditions: false,
      applicationReady: true,
      errorList: [],
      supervisorRegistered: false,
      checkedValidations: false,
      collectedTablesArray: [],
      workplace: undefined,
    };
  },
  validations() {
    return {
      companyInfoNationalId: {
        required,
        minLength: minLength(11), // 10 digits + 1 hyphen
        validateNationalId,
        verifyNationalId: () => {
          return this.companyNationalIdVerified;
        },
      },
      companyInfoName: {
        required,
        minLength: minLength(1),
        validateStringMinMaxLength: validateStringMinMaxLength(1, 100),
      },
      companyInfoPhone: {
        required,
        minLength: minLength(8), // 7 digits + 1 hyphen
        validatePhoneNumber,
      },
      companyInfoEmail: {
        required,
        validateEmail,
      },
      companyInfoAddress: {
        required,
        validateStringMinMaxLength: validateStringMinMaxLength(1, 100),
      },
      companyInfoAreaCode: {
        required,
        validateAreaCode,
      },
      selected: {
        required,
      },
      availablePositions: {
        required,
        minValue: minValue(1),
      },
      selectAllCheckBoxes: {},
    };
  },
  computed: {
    options() {
      return this.professionList
        .filter((v) => this.form.application.professions.filter((p) => p.id === v.id).length === 0)
        .map((v) => ({ value: v.id, label: v.name }));
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async handleCompanyNationalIdInput() {
      this.companyInfoNationalId = formatNationalId(this.companyInfoNationalId);
      if (this.companyInfoNationalId.length === formattedNationalIdLength) {
        try {
          const response = await fetchInfoFromNationalRegistry(removeHyphen(this.companyInfoNationalId));
          const { name, address, postalCode } = response;
          this.companyInfoName = name;
          this.companyInfoAddress = address;
          this.companyInfoAreaCode = postalCode;
          this.companyNationalIdVerified = true;
        } catch {
          this.companyNationalIdVerified = false;
          this.companyInfoName = '';
          this.companyInfoAddress = '';
          this.companyInfoAreaCode = '';
        }
      }
    },
    handleCompanyPhoneNumberInput() {
      this.companyInfoPhone = formatPhoneNumber(this.companyInfoPhone);
    },
    fileAddSuccess(supervisorFiles) {
      this.hasFiles.push(supervisorFiles);
    },
    async applicationFileAdd(supervisorType, cardIndex, supervisorIndex) {
      let allFieldsValid = false;
      let supervisorData;
      if (supervisorType === masterRoleString) {
        this.cards[cardIndex].supervisorRegistered = false;
        supervisorData = this.cards[cardIndex].supervisorDataMaster[supervisorIndex];
      }
      if (supervisorType === extraMasterRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataExtraMaster[supervisorIndex];
      }
      if (supervisorType === journeymanRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataJourneyman[supervisorIndex];
      }
      if (supervisorType === monitorRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataMonitor[supervisorIndex];
      }
      if (
        validateNationalId(supervisorData.nationalId) &&
        validateStringMinMaxLength(supervisorData.name, 1, 100) &&
        validatePhoneNumber(supervisorData.phone) &&
        validateEmail(supervisorData.email)
      ) {
        if (supervisorType === masterRoleString) {
          this.cards[cardIndex].supervisorRegistered = true;
        }
        allFieldsValid = true;
      }

      if (allFieldsValid) {
        const supervisor = {
          applicationId: this.applicationId,
          ssn: removeHyphen(supervisorData.nationalId),
          name: supervisorData.name,
          phone_number: removeHyphen(supervisorData.phone),
          email: supervisorData.email,
          role: roleMap[supervisorType],
        };
        supervisor.id = await this.addSupervisor(supervisor);
        supervisorData.id = supervisor.id;
        this.$refs.applicationFileAdd.show([supervisor], this.applicationId);
      }
    },
    addFileLocked(item) {
      if (
        validateNationalId(item.nationalId) &&
        validateStringMinMaxLength(item.name, 1, 100) &&
        validatePhoneNumber(item.phone) &&
        validateEmail(item.email)
      ) {
        return false;
      }
      return true;
    },
    fileUploaded(newFile) {
      return this.hasFiles.length > 0 && this.hasFiles.some((file) => file.supervisorId === newFile.id);
    },
    validateInput(key, field, value, cardIndex, supervisorType) {
      // Validate input and update validation state
      if (supervisorType === masterRoleString) {
        const index = this.cards[cardIndex].supervisorDataMaster.findIndex((row) => row.key === key);
        if (field === 'nationalId') {
          this.cards[cardIndex].supervisorDataMaster[index].validate.nationalId = validateNationalId(value);
        } else if (field === 'name') {
          this.cards[cardIndex].supervisorDataMaster[index].validate.name = validateStringMinMaxLength(value, 1, 100);
        } else if (field === 'phone') {
          this.cards[cardIndex].supervisorDataMaster[index].validate.phone = validatePhoneNumber(value);
        } else if (field === 'email') {
          this.cards[cardIndex].supervisorDataMaster[index].validate.email = validateEmail(value);
        }
        return;
      }
      if (supervisorType === extraMasterRoleString) {
        const index = this.cards[cardIndex].supervisorDataExtraMaster.findIndex((row) => row.key === key);
        if (field === 'nationalId') {
          this.cards[cardIndex].supervisorDataExtraMaster[index].validate.nationalId = validateNationalId(value);
        } else if (field === 'name') {
          this.cards[cardIndex].supervisorDataExtraMaster[index].validate.name = validateStringMinMaxLength(value, 1, 100);
        } else if (field === 'phone') {
          this.cards[cardIndex].supervisorDataExtraMaster[index].validate.phone = validatePhoneNumber(value);
        } else if (field === 'email') {
          this.cards[cardIndex].supervisorDataExtraMaster[index].validate.email = validateEmail(value);
        }
        return;
      }
      if (supervisorType === journeymanRoleString) {
        const index = this.cards[cardIndex].supervisorDataJourneyman.findIndex((row) => row.key === key);
        if (field === 'nationalId') {
          this.cards[cardIndex].supervisorDataJourneyman[index].validate.nationalId = validateNationalId(value);
        } else if (field === 'name') {
          this.cards[cardIndex].supervisorDataJourneyman[index].validate.name = validateStringMinMaxLength(value, 1, 100);
        } else if (field === 'phone') {
          this.cards[cardIndex].supervisorDataJourneyman[index].validate.phone = validatePhoneNumber(value);
        } else if (field === 'email') {
          this.cards[cardIndex].supervisorDataJourneyman[index].validate.email = validateEmail(value);
        }
        return;
      }
      if (supervisorType === monitorRoleString) {
        const index = this.cards[cardIndex].supervisorDataMonitor.findIndex((row) => row.key === key);
        if (field === 'nationalId') {
          this.cards[cardIndex].supervisorDataMonitor[index].validate.nationalId = validateNationalId(value);
        } else if (field === 'name') {
          this.cards[cardIndex].supervisorDataMonitor[index].validate.name = validateStringMinMaxLength(value, 1, 100);
        } else if (field === 'phone') {
          this.cards[cardIndex].supervisorDataMonitor[index].validate.phone = validatePhoneNumber(value);
        } else if (field === 'email') {
          this.cards[cardIndex].supervisorDataMonitor[index].validate.email = validateEmail(value);
        }
      }
    },
    async loadProfessions() {
      this.loading = true;
      try {
        // Get list of professions that have valid versions (verkþættir)
        this.professionList = [];
        this.categoryList.forEach((category) => {
          category.professions.forEach((profession) => {
            if (profession.versions.length > 0) {
              this.professionList.push({
                id: profession.id,
                name: profession.professionName,
                category: category.name,
              });
            }
          });
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja lista yfir starfsgreinar.
                Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async loadCategoryList() {
      this.loading = true;
      this.categoryList = [];
      try {
        const response = await core.professionCategoryVersionsList();
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadFactorCategoryList(category) {
      try {
        const res = await core.factorCategoryList(null, category);
        return res.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja lista yfir verkflokka/verkþætti.
                Vinsamlegast reyndu aftur.`,
        );
        return [];
      } finally {
        this.loading = false;
      }
    },
    professionsChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }
      val.forEach((v) => {
        this.form.application.professions.push({
          id: v.value,
          name: v.label,
          slots: 0,
          _showDetails: true, // Til þess að birta verkþætti á fallegan hátt.
        });
        this.addNewCard(v);
      });
    },
    toggleSelectAllFactors(card, index) {
      if (this.cards[index].factorList) {
        this.cards[index].factorList = this.cards[index].factorList.map((row) => {
          return { ...row, selected: this.cards[index].selectAllCheckBoxes };
        });
      }
      this.checkBoxTableKey += 1;
    },
    isAnyCheckboxSelected(index) {
      this.cards[index].professionFactorSelected = false;
      this.cards[index].factorList.forEach((row) => {
        if (row.selected) {
          this.cards[index].professionFactorSelected = true;
        }
      });
      return this.cards[index].professionFactorSelected;
    },
    async updateCard(key, field, value, cardIndex, supervisorType) {
      let supervisorData;
      if (supervisorType === masterRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataMaster.find((row) => row.key === key);
      }
      if (supervisorType === extraMasterRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataExtraMaster.find((row) => row.key === key);
      }
      if (supervisorType === journeymanRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataJourneyman.find((row) => row.key === key);
      }
      if (supervisorType === monitorRoleString) {
        supervisorData = this.cards[cardIndex].supervisorDataMonitor.find((row) => row.key === key);
      }
      let newValue = value;
      if (field === 'nationalId') {
        newValue = formatNationalId(newValue);
        if (newValue.length === formattedNationalIdLength) {
          const response = await fetchNameFromNationalRegistry(removeHyphen(newValue));
          try {
            const { name } = response;
            supervisorData.name = name;
          } catch {
            supervisorData.name = '';
            supervisorData.validate.nationalId = false;
          }
        }
      }
      if (field === 'phone') {
        newValue = formatPhoneNumber(newValue);
      }
      supervisorData[field] = newValue === '' ? null : newValue;
      this.validateInput(key, field, newValue, cardIndex, supervisorType);
    },
    async loadWorkplace() {
      try {
        const response = await myPage.getWorkplace();
        const workplaceData = response.data;
        this.workplace = {
          id: workplaceData.id,
          nationalId: formatNationalId(workplaceData.ssn),
          name: workplaceData.name,
          address: workplaceData.address,
          zipcode: workplaceData.zipcode,
          phone: formatPhoneNumber(workplaceData?.phone?.toString()),
          email: workplaceData.email,
        };

        if (workplaceData.ssn) {
          this.companyInfoNationalId = this.workplace.nationalId;
          this.companyInfoName = this.workplace.name;
          this.companyInfoAddress = this.workplace.address;
          this.companyInfoAreaCode = this.workplace.zipcode;
          this.companyInfoPhone = this.workplace.phone;
          this.companyInfoEmail = this.workplace.email;
          // workplace is already verified
          this.companyNationalIdVerified = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.status}) kom upp við að sækja vinnustaði. Vinsamlegast reyndu aftur.`);
      }
    },
    async initApplication() {
      try {
        const item = {
          ssn: this.form.application.ssn,
        };
        const response = await application.applicationCreate(item);
        this.applicationId = response.data.applicationId;
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur`);
      } finally {
        this.loading = false;
      }
    },
    async addNewCard(profession) {
      let categoryUUID = null;
      this.categoryList.forEach((applicationProcessor) => {
        applicationProcessor.professions.forEach((category) => {
          if (category.id === profession.value) {
            if (category.versions.length > 0) {
              categoryUUID = category.versions[0].id;
            } else {
              categoryUUID = applicationProcessor.uuid;
            }
          }
        });
      });
      let factorList = []; // adding verkþættir or factors
      if (categoryUUID) {
        factorList = await this.loadFactorCategoryList(categoryUUID).catch((e) => {
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja lista yfir verkflokka/verkþætti.
                      Vinsamlegast reyndu aftur.`,
          );
        });
        factorList = factorList.filter((item) => typeof item === 'object' && item !== null);
      }
      // below the contents of each jobtype application is populated.
      this.cards.push({
        id: this.form.application.professions[this.tableIdCounter].id,
        tableName: profession.label,
        tableId: this.tableIdCounter,
        categoryUUID,
        slots: 1,
        factorList,
        selectAllCheckBoxes: false,
        supervisorRegistered: false,
        professionFactorSelected: false,
        masterTableCounter: 0,
        journeymanTableCounter: 0,
        monitorTableCounter: 0,
        supervisorDataMaster: [
          {
            key: randomId(),
            id: 0,
            nationalId: '',
            name: '',
            phone: '',
            email: '',
            licence: null,
            addPerson: '',
            role: 1,
            validate: {
              nationalId: null,
              name: null,
              phone: null,
              email: null,
            },
          },
        ],
        supervisorDataExtraMaster: [],
        supervisorDataJourneyman: [],
        supervisorDataMonitor: [],
      });
      this.cards[this.tableIdCounter].factorList = [];
      factorList.forEach((factorCategory) => {
        this.cards[this.tableIdCounter].factorList.push({
          id: factorCategory.id,
          title: factorCategory.name,
          description: '',
          selected: false,
        });
      });
      this.tableIdCounter += 1;
    },
    removeCard(index, profession) {
      this.cards.splice(index, 1);
      this.form.application.professions = this.form.application.professions.filter((p) => p.name !== profession);
      this.tableIdCounter -= 1;
    },
    addRowTableExtraMaster(index) {
      const newRow = {
        key: randomId(),
        id: null,
        nationalId: '',
        name: '',
        phone: '',
        email: '',
        licence: null,
        addPerson: '',
        role: 4,
        validate: {
          nationalId: null,
          name: null,
          phone: null,
          email: null,
        },
      };
      this.cards[index].supervisorDataExtraMaster.push(newRow);
    },
    addRowTableJourneyman(index) {
      const newRow = {
        key: randomId(),
        id: null,
        nationalId: '',
        name: '',
        phone: '',
        email: '',
        licence: null,
        addPerson: '',
        role: 2,
        validate: {
          nationalId: null,
          name: null,
          phone: null,
          email: null,
        },
      };
      this.cards[index].supervisorDataJourneyman.push(newRow);
    },
    addRowTableMonitor(index) {
      const newRow = {
        key: randomId(),
        id: null,
        nationalId: '',
        name: '',
        phone: '',
        email: '',
        licence: null,
        addPerson: '',
        role: 3,
        validate: {
          nationalId: null,
          name: null,
          phone: null,
          email: null,
        },
      };
      this.cards[index].supervisorDataMonitor.push(newRow);
    },
    removeRowExtraMaster(key, cardIndex) {
      const index = this.cards[cardIndex].supervisorDataExtraMaster.findIndex((row) => row.key === key);
      this.cards[cardIndex].supervisorDataExtraMaster.splice(index, 1);
      this.cards[cardIndex].supervisorDataExtraMaster = [...this.cards[cardIndex].supervisorDataExtraMaster];
    },
    removeRowJourneyman(key, cardIndex) {
      const index = this.cards[cardIndex].supervisorDataJourneyman.findIndex((row) => row.key === key);
      this.cards[cardIndex].supervisorDataJourneyman.splice(index, 1);
      this.cards[cardIndex].supervisorDataJourneyman = [...this.cards[cardIndex].supervisorDataJourneyman];
    },
    removeRowMonitor(key, cardIndex) {
      const index = this.cards[cardIndex].supervisorDataMonitor.findIndex((row) => row.key === key);
      this.cards[cardIndex].supervisorDataMonitor.splice(index, 1);
      this.cards[cardIndex].supervisorDataMonitor = [...this.cards[cardIndex].supervisorDataMonitor];
    },
    seeIfMasterEmpty(item) {
      if (item === null || item === '') {
        return false;
      }
      return true;
    },
    // Restructure the collected data to fit the backend
    combineTables() {
      this.collectedTablesArray = [];
      this.cards.forEach((row) => {
        this.selectedFactors = [];
        row.factorList.forEach((item) => {
          if (item.selected === true) {
            this.selectedFactors.push({
              id: item.id,
              name: item.title,
              profession: this.form.application.professions[row.tableId].id,
              selected: item.selected,
            });
          }
        });

        this.supervisorsCollected = [];
        row.supervisorDataMaster.forEach((supervisor) => {
          this.supervisorsCollected.push({
            id: supervisor.id,
            name: supervisor.name,
            ssn: removeHyphen(supervisor.nationalId),
            mobile: removeHyphen(supervisor.phone),
            email: supervisor.email,
            role: supervisor.role,
          });
        });

        row.supervisorDataExtraMaster.forEach((supervisor) => {
          if (this.seeIfMasterEmpty(supervisor.nationalId)) {
            this.supervisorsCollected.push({
              id: supervisor.id,
              name: supervisor.name,
              ssn: removeHyphen(supervisor.nationalId),
              mobile: removeHyphen(supervisor.phone),
              email: supervisor.email,
              role: supervisor.role,
            });
          }
        });

        row.supervisorDataJourneyman.forEach((supervisor) => {
          if (this.seeIfMasterEmpty(supervisor.nationalId)) {
            this.supervisorsCollected.push({
              id: supervisor.id,
              name: supervisor.name,
              ssn: removeHyphen(supervisor.nationalId),
              mobile: removeHyphen(supervisor.phone),
              email: supervisor.email,
              role: supervisor.role,
            });
          }
        });

        row.supervisorDataMonitor.forEach((supervisor) => {
          if (this.seeIfMasterEmpty(supervisor.nationalId)) {
            this.supervisorsCollected.push({
              id: supervisor.id,
              name: supervisor.name,
              ssn: removeHyphen(supervisor.nationalId),
              mobile: removeHyphen(supervisor.phone),
              email: supervisor.email,
              role: supervisor.role,
            });
          }
        });

        this.collectedTablesArray.push({
          id: row.id,
          slots: row.slots,
          factors: this.selectedFactors,
          supervisors: this.supervisorsCollected,
        });
      });
    },
    ...mapActions({
      displaySuccess: 'displaySuccess',
      displayError: 'displayError',
    }),
    async validateBeforeSubmit() {
      // combine all of the supervisor tables
      this.combineTables();

      // validate inputs
      this.validateApplication();

      // start the process
      this.loading = true;
      try {
        this.submitted = true;
        if (this.applicationReady === false) {
          this.displayError('Umsókn er ekki fullnægjandi. Vinsamlegast lagaðu villur áður en umsókn er send inn.');
          return;
        }
        try {
          const item = {
            applicationId: this.applicationId,
            ssn: removeHyphen(this.companyInfoNationalId),
            name: this.companyInfoName,
            address: this.companyInfoAddress,
            zipcode: this.companyInfoAreaCode,
            phone_number: removeHyphen(this.companyInfoPhone),
            email: this.companyInfoEmail,
            professions: JSON.stringify(this.collectedTablesArray),
          };
          await application.applicationUpdate(this.applicationId, item);
          this.displaySuccess('Umsókn stofnuð.');
          this.isEditing = true;
          this.$router.push({ name: 'Index' });
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur`);
        } finally {
          this.loading = false;
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    validateProfessionCount() {
      if (this.cards === null || this.cards === undefined || !this.cards || this.cards.length < 1) {
        return false;
      }
      return true;
    },
    validateApplication() {
      this.applicationReady = true;
      this.errorList = [];
      const validations = [];

      // touch all companyInfo fields to show errors
      this.$v.$touch();

      // check if the company info is correct
      validations.push(!this.$v.companyInfoNationalId.$invalid);
      validations.push(!this.$v.companyInfoName.$invalid);
      validations.push(!this.$v.companyInfoPhone.$invalid);
      validations.push(!this.$v.companyInfoEmail.$invalid);
      validations.push(!this.$v.companyInfoAddress.$invalid);
      validations.push(!this.$v.companyInfoAreaCode.$invalid);
      validations.push(this.validateProfessionCount());

      // see if the inputs for the apprentiship are correct
      this.cards.forEach((row) => {
        this.isAnyCheckboxSelected(row.tableId);
        if (row.slots < 1) {
          this.errorList.push(row.tableName.concat('Lámarks fjöldi starfsumsóknar er 1 hjá '.concat(row.tableName)));
          this.applicationReady = false;
        }
        if (row.professionFactorSelected === false) {
          this.errorList.push(row.tableName.concat('Velja þarf lámark einn verkþátt fyrir '.concat(row.tableName)));
          this.applicationReady = false;
        }
        this.collectedTablesArray[row.tableId].supervisors.forEach((supervisor) => {
          if (
            !validateNationalId(supervisor.ssn) ||
            !validatePhoneNumber(supervisor.mobile) ||
            !validateEmail(supervisor.email) ||
            !validateStringMinMaxLength(supervisor.name, 1, 100)
          ) {
            validations.push(false); // if any of the checks are false
            this.errorList.push('Vantar upplýsingar um tilsjónaraðila í umsóknina fyrir '.concat(row.tableName));
          }

          if (supervisor.role === 1) {
            // check if master has files
            const supervisorFilesIndex = this.hasFiles.findIndex((file) => file.supervisorId === supervisor.id && supervisor.role === 1);
            if (!this.hasFiles?.[supervisorFilesIndex]) {
              this.errorList.push('Vantar viðhengi í umsóknina fyrir '.concat(row.tableName));
              this.applicationReady = false;
            }
          }
          // see if errors are coming from check functions
          if (validations.some((value) => value === false)) {
            this.applicationReady = false;
          }
        });
      });
      if (!this.termsAndConditions) {
        this.errorList.push('Samþykkja þarf skilyrði umsóknar neðst');
        this.checkedValidations = true;
        this.applicationReady = false;
      }
    },
    async addSupervisor(supervisor) {
      this.form.supervisor = { name: '', ssn: '', mobile: '', id: '', email: '' };
      // sending in and getting in return supervisorID
      const response = await application.supervisorList(supervisor).catch((e) => {
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að bæta við tilsjónaraðila.
                Vinsamlegast reyndu aftur.`,
        );
      });
      // setting temp forms supervisor
      this.form.supervisor.id = response.data.supervisorId;
      this.form.application.supervisors.push(this.form.supervisor);
      return response.data.supervisorId;
    },
  },
  created() {
    this.form.application.ssn = this.loggedInUser.ssn;
    this.loadWorkplace();
    this.initApplication();
    this.loadCategoryList().then(() => {
      this.loadProfessions();
    });
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
};
</script>
<style>
#checkboxTable th:nth-child(1) {
  width: 5%;
}

h5.card-header {
  font-weight: bold;
}

.invalid-label {
  color: indianred;
}

.invalid-input {
  color: indianred;
  border-color: indianred;
  outline-color: indianred;
}

.invalid-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 92, 92, 0.5);
}
</style>
