<template>
  <b-card no-body>
    <div slot="header" @click="collpaseCategory()" style="cursor: pointer">
      <b-row>
        <b-col cols="11">
          <h5 class="mb-0">
            {{ item.name }}
          </h5>
        </b-col>
        <b-col>
          <div class="pull-right h5 mb-0">
            <i class="fa fa-fw" :class="{ 'fa-chevron-down': collapse, 'fa-chevron-right': !collapse }"></i>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="collapse" v-model="collapse">
      <div class="card-body">
        <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
        <div v-else>
          <div v-if="item.professions.length == 0"><i>Engar niðurstöður fundust.</i></div>
          <div v-for="(profession, idx) in item.professions" :key="idx">
            <div>
              <table class="table">
                <td>
                  <strong>{{ profession.professionName }}</strong>
                </td>
              </table>
            </div>
            <div v-if="profession.versions.length === 0"><i>Engar útgáfur fundust</i></div>
            <table v-else class="table">
              <tr v-for="version in profession.versions" :key="version.id">
                <td width="1">
                  <b-link
                    @click.prevent="careerbookVersionModalLogin(profession, version)"
                    class="btn btn-secondary pt-0 pb-0 pl-1 pr-1 mt-1"
                    v-b-tooltip.hover
                    title="Skoða útgáfu"
                    ><i class="fa fa-search"></i
                  ></b-link>
                </td>
                <td style="vertical-align: bottom"><strong>Útgáfa: </strong>{{ version.version }}</td>
              </tr>
            </table>
          </div>
        </div>
        <careerbook-version-modal-login
          ref="careerbookVersionModalLogin"
          @successful="careerbookVersionModalLoginViewed"
        ></careerbook-version-modal-login>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import CareerbookVersionModalLogin from '@/components/login/modal/CareerbookVersionModalLogin.vue';

export default {
  name: 'profession-category-item',
  props: ['item', 'notCollapsed'],
  components: {
    CareerbookVersionModalLogin,
  },
  data() {
    return {
      collapse: false,
      loading: false,
      professionList: [],
      name: null,
      profession: '',
      skipAuth: false,
    };
  },
  methods: {
    /*
     * Collapse-ar starfsgreinaflokk og nær í professions ef ekki er búið að því
     */
    async collpaseCategory() {
      this.collapse = !this.collapse;
      this.loading = false;
    },
    careerbookVersionModalLogin(profession, version) {
      this.$refs.careerbookVersionModalLogin.show(profession, version);
    },
    careerbookVersionModalLoginViewed() {
      this.loadList();
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if (this.notCollapsed) {
      this.collpaseCategory();
    }
  },
};
</script>
