<template>
  <div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Kennitala"
        label-for="ssn"
        :state="submitted && errors.has('ssn') ? false : null"
        :feedback="errors.first('ssn')"
      >
        <b-input-group>
          <b-form-input
            id="ssn"
            name="ssn"
            type="text"
            required
            placeholder="Sláðu inn kennitölu"
            v-model="form.ssn"
            v-validate="'required|length:10'"
            data-vv-as="kennitala"
            :disabled="id !== null"
            :state="(submitted || userCheckSuccess !== null) && errors.has('ssn') ? false : null"
            @input="checkUser"
          >
          </b-form-input>
          <b-input-group-addon v-if="userCheckSuccess !== null" is-text>
            <strong class="text-danger" v-if="userCheckSuccess === false"><i class="fa fa-exclamation"></i></strong>
          </b-input-group-addon>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Nafn" label-for="name" description="Fullt nafn notanda">
        <b-form-input id="name" name="name" type="text" v-model="form.name" v-validate="'required'" data-vv-as="nafn" :readonly="true">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Símanúmer" label-for="mobile" :state="submitted && errors.has('mobile') ? false : null">
        <b-form-input
          id="mobile"
          name="mobile"
          type="tel"
          requried
          :state="submitted && errors.has('mobile') ? false : null"
          v-model="form.mobile"
          v-validate="'required'"
          data-vv-as="simanumer"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label="Netfang" label-for="email">
        <b-form-input
          id="email"
          name="email"
          type="email"
          require
          :state="submitted && errors.has('email') ? false : null"
          v-validate="'required'"
          data-vv-as="netfang"
          v-model="form.email"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label="Virkur" label-for="is_active">
        <b-form-checkbox id="is_active" name="is_active" v-model="form.is_active" required data-vv-as="virkur"></b-form-checkbox>
      </b-form-group>
      <b-form-group label="Admin" label-for="is_admin">
        <b-form-checkbox id="is_admin" name="is_admin" v-model="form.is_admin" required data-vv-as="admin"></b-form-checkbox>
      </b-form-group>
      <b-form-group label="Getur stofnað umsókn" label-for="can_create_application">
        <b-form-checkbox
          id="can_create_application"
          name="can_create_application"
          v-model="form.can_create_application"
          required
          data-vv-as="can_create_application"
        >
        </b-form-checkbox>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'user-create-form',
  props: ['id'],
  data() {
    return {
      loading: false,
      userCheckSuccess: null,
      submitted: false,
      // id: null,
      form: {
        ssn: '',
        name: '',
        mobile: '',
        email: '',
        is_active: true,
        is_admin: false,
        can_create_application: false,
      },
    };
  },
  methods: {
    async checkUser(ssn) {
      this.form.name = '';
      if (ssn && ssn.length === 10) {
        try {
          this.loading = true;
          const response = await auth.userCheck(ssn);
          this.userCheckSuccess = response.data.name && !response.data.user;
          if (!response.data.name) {
            this.displayError('Kennitala finnst ekki í þjóðskrá');
            this.errors.add({
              field: 'username',
              msg: 'Þessi kennitala finnst ekki í þjóðskrá',
              scope: 'server',
            });
          }

          if (response.data.user) {
            this.displayError('Kennitala er nú þegar skráð');
            this.errors.add({
              field: 'username',
              msg: 'Þessi notandi er nú þegar til',
              scope: 'server',
            });
          }
          if (response.data.name && !response.data.user) {
            this.form.name = response.data.name;
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá notanda.
              Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        try {
          const response = await auth.userCreate({
            ssn: this.form.ssn,
            name: this.form.name,
            mobile: this.form.mobile,
            email: this.form.email,
            isActive: this.form.is_active ? 1 : 0,
            isAdmin: this.form.is_admin ? 1 : 0,
            canCreateApplication: this.form.can_create_application ? 1 : 0,
          });
          this.id = response.data.id;
          this.displaySuccess(`Notandinn „${this.form.name}“ skráður.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    async loadUser(id) {
      const response = await auth.userDetail(id);
      this.$set(this.form, 'name', response.data.name);
      this.$set(this.form, 'ssn', response.data.ssn);
      this.$set(this.form, 'email', response.data.email);
      this.$set(this.form, 'mobile', response.data.mobile);
      this.$set(this.form, 'is_active', response.data.is_active === 1);
      this.$set(this.form, 'is_admin', response.data.is_admin === 1);
      this.$set(this.form, 'can_create_application', response.data.can_create_application === 1);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.id) {
      this.loadUser(this.id);
    }
  },
};
</script>
