<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h3 class="mb-4 text-dark font-weight-bold">Mínar umsóknir</h3>
        </b-col>
        <b-col>
          <div>
            <b-link class="btn btn-secondary pull-right" :to="{ name: 'ApplicationCreateForm' }">
              <i class="fa fa-fw fa-plus-circle"></i>
              Stofna umsókn
            </b-link>
          </div>
        </b-col>
      </b-row>
      <i class="fa fa-fw fa-spin fa-spinner" v-if="loading"></i>
      <table class="table table-hover table-striped" v-else>
        <thead v-if="applicationList.length > 0">
          <tr>
            <th></th>
            <th>Starfsgrein</th>
            <th>Dags. umsóknar</th>
            <th>Staða umsóknar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="applicationList.length == 0">
            <td colspan="2">
              <i>Engar leitarniðurstöður fundust.</i>
            </td>
          </tr>
          <tr v-for="(application, index) in applicationList" :key="index">
            <td>
              {{ application.application_id }}
            </td>
            <td>
              <span v-for="(profession, professionIdx) in application.professions" :key="profession.id">
                <span v-if="professionIdx != 0">, </span>{{ profession.name }}({{ profession.slots }})
              </span>
              <div>
                <span>
                  <i>Tilsjónaraðili:</i>
                  <span v-for="(supervisor, supervisorIdx) in application.supervisors.filter((supervisor) => supervisor.is_deleted != 1)" :key="supervisorIdx">
                    <i style="font-size: 11px"> {{ supervisor.name }} </i>
                  </span>
                </span>
              </div>
            </td>
            <td>
              {{ application.date | moment('DD.MM.YYYY') }}
            </td>
            <td v-if="application.status === 0">Í vinnslu</td>
            <td v-if="application.status === 1">Hafnað</td>
            <td v-if="application.status === 2">Samþykkt</td>
            <td v-if="application.status === 3">Óafgreidd</td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="pagination && pagination.totalPages > 1"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      ></pagination>
    </b-card>
    <user-auto-update-crud-modal ref="userAutoUpdateModal" @successful="userAutoUpdated"></user-auto-update-crud-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import UserAutoUpdateCrudModal from '@/components/user/UserAutoUpdateCrudModal.vue';
import application from '@/api/application';
import Pagination from '@/components/layout/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'application-list',
  components: {
    Pagination,
    UserAutoUpdateCrudModal,
  },
  mixins: [paginationMixin],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      loading: false,
      applicationList: [],
      page: null,
      pagination: null,
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      try {
        const response = await application.myApplications(page, limit, 1);
        this.applicationList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    userAutoUpdated() {
      this.loadList();
    },
  },
  created() {
    this.loadList();
  },
  mounted() {
    if (!this.loggedInUser.email || !this.loggedInUser.mobile) {
      this.$refs.userAutoUpdateModal.show();
    }
  },
  ...mapActions({
    displayError: 'displayError',
    displaySuccess: 'displaySuccess',
  }),
};
</script>
