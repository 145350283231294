import Vue from 'vue';

import store from 'store';

import config from '../config';

export default {
  /**
   * Sækja innskráningu
   */
  getToken() {
    return store.get(config.LOGIN_TOKEN);
  },

  /**
   * Vefþjónusta til þess að athuga með innskráningu
   */
  ping() {
    return Vue.http
      .get(config.PING_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Innskráður notandi
   */
  me() {
    return Vue.http
      .get(config.ME_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Setja token
   *
   * @param {String} toke
   */
  setToken(token) {
    store.set(config.LOGIN_TOKEN, token);
  },
  /**
   * Hreinsa innskráningu.
   */
  removeToken() {
    store.remove(config.LOGIN_TOKEN);
    store.remove(config.LOGIN_TOKEN_EXPIRE);
  },
  /**
   * Setja expire date
   *
   * @param {String} toke
   */
  setTokenExpire(timeleft) {
    const d = new Date();
    d.setTime(d.getTime() + parseInt(timeleft, 10) * 1000);
    store.set(config.LOGIN_TOKEN_EXPIRE, d);
  },
  /**
   * Vefþjónusta til þess að tékka á onetime key.
   *
   * @param {String} payload JWT innskráning til að fríska upp á.
   */
  checkLogin(token) {
    return Vue.http
      .post(config.CHECK_LOGIN_URL, { onetime: token }, { skipAuthorization: true })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Sækja expire date.
   */
  getTokenExpire() {
    return store.get(config.LOGIN_TOKEN_EXPIRE);
  },
  /**
   * Ath hvort JWT innskráning sé runnin út
   * @param {String} decoded Innihald JWT innskráningar.
   */
  isTokenExpired() {
    const jwtTokenExpires = this.getTokenExpire();

    if (!jwtTokenExpires) {
      return true;
    }
    return new Date().getTime() > new Date(jwtTokenExpires);
  },
  /**
   * Vefþjónusta sem sækir system status (villu-, viðvörunar- og upplýsingaskilaboð)
   * fyrir síður á login síðunni
   *
   */
  showSystemStatusLogin(type) {
    return Vue.http
      .get(
        config.SHOW_SYSTEM_STATUS_LOGIN,
        {
          params: {
            type,
          },
        },
        { skipAuthorization: true },
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  userList(page = null, limit = null, searchName = null) {
    return Vue.http
      .get(config.USER_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  userDetail(id) {
    return Vue.http
      .get(config.USER_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((response) => Promise.reject(response));
  },
  userCreate(data) {
    return Vue.http
      .post(config.USER_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  userCheck(ssn) {
    return Vue.http
      .get(config.USER_CHECK_URL.replace('{ssn}', encodeURIComponent(ssn)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  userUpdate(id, data) {
    return Vue.http
      .put(config.USER_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
