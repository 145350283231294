<template>
  <page-login>
    <br />
    <b-card>
      <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
      <div v-else>
        <h5 class="my-4">Yfirlit yfir útgáfur</h5>
        <div v-if="categoryList.length == 0"><i>Engar niðurstöður fundust.</i></div>
        <profession-category-item-login class="mb-3" v-for="(category, index) in categoryList" :key="index" :item="category" ref="category">
        </profession-category-item-login>
      </div>
    </b-card>
  </page-login>
</template>
<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import ProfessionCategoryItemLogin from '@/components/login/version/ProfessionCategoryItemLogin.vue';
import PageLogin from '@/components/layout/PageLogin.vue';

export default {
  name: 'version-list-login',
  components: {
    ProfessionCategoryItemLogin,
    PageLogin,
  },
  data() {
    return {
      loading: false,
      categoryList: [],
      collapse: false,
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.categoryList = [];
      try {
        const response = await core.professionCategoryVersionsList();
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    collapseProfessionCategoryVersion() {
      this.collapse = !this.collapse;
      this.loading = false;
      if (this.categoryList.length === 0 && this.collapse) {
        this.loadList();
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    this.loadList();
  },
};
</script>
