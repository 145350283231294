import Vue from 'vue';
import Router from 'vue-router';

import Index from '@/views/Index.vue';
import Login from '@/views/Login.vue';
import ApplicationList from '@/views/ApplicationList.vue';
import UsersList from '@/views/UsersList.vue';
import ApplicationCreateForm from '@/components/application/create/ApplicationCreateForm.vue';
import MyPage from '@/components/myPage/MyPage.vue';

import VersionListLogin from '@/components/login/views/VersionListLogin.vue';
import WorkplaceList from '@/components/login/views/WorkplaceList.vue';

import guards from './guards';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/login/:token',
      name: 'Login',
      component: Login,
      beforeEnter: guards.logoutBefore,
    },
    {
      path: '/mitt-svaedi',
      name: 'MyPage',
      component: MyPage,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/umsoknir',
      name: 'ApplicationList',
      component: ApplicationList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.isAdmin]),
    },
    {
      path: '/stofna',
      name: 'ApplicationCreateForm',
      component: ApplicationCreateForm,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/notendur',
      name: 'UsersList',
      component: UsersList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.isAdmin]),
    },
    {
      path: '/utgafur',
      name: 'VersionListLogin',
      component: VersionListLogin,
    },
    {
      path: '/vinnustadir',
      name: 'WorkplaceList',
      component: WorkplaceList,
    },
  ],
});
