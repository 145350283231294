<template>
  <page>
    <b-card>
      <b-row>
        <b-col>
          <h3 v-if="loggedInUser.is_admin === 1" class="text-dark font-weight-bold">Umsjón Umsókna</h3>
          <h3 v-else class="text-dark font-weight-bold">Mitt svæði</h3>
        </b-col>
      </b-row>
      <div class="mb-4 container p-0">
        <div v-if="editCompanyInfo">
          <div class="d-flex mb-1 justify-content-end pr-0 col-12">
            <b-button @click="editCompanyInfoSave" variant="primary" class="mr-1 button-radius">Vista breytingar</b-button>
            <b-button @click="editCompanyInfoToggle" variant="primary" class="button-radius cancel-button">Hætta við breytingar</b-button>
          </div>
          <div class="col-12 border p-4">
            <div>
              <label for="name" class="text-dark font-weight-bold mb-0">Vinnustaður:</label>
              <b-form-input
                type="text"
                v-model="companyInfo.formData.name"
                id="name"
                @input="() => validateCompanyInfo('name', companyInfo.formData.name)"
                :class="companyInfo.formData.validate.name === false && 'invalid-input'"
              />
              <label class="invalid-label" v-if="companyInfo.formData.validate.name === false">Nafn er ekki gilt.</label>
            </div>
            <div>
              <label for="address" class="text-dark font-weight-bold mb-0">Heimilisfang:</label>
              <b-form-input
                type="text"
                v-model="companyInfo.formData.address"
                id="address"
                @input="() => validateCompanyInfo('address', companyInfo.formData.address)"
                :class="companyInfo.formData.validate.address === false && 'invalid-input'"
              />
              <label class="invalid-label" v-if="companyInfo.formData.validate.address === false">Heimilisfang er ekki gilt.</label>
            </div>
            <div>
              <label for="zipcode" class="text-dark font-weight-bold mb-0">Póstnúmer:</label>
              <b-form-input
                type="text"
                v-model="companyInfo.formData.zipcode"
                id="zipcode"
                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                maxlength="3"
                @input="() => validateCompanyInfo('zipcode', companyInfo.formData.zipcode)"
                :class="companyInfo.formData.validate.zipcode === false && 'invalid-input'"
              />
              <label class="invalid-label" v-if="companyInfo.formData.validate.zipcode === false">Póstnúmer er ekki gilt.</label>
            </div>
            <div>
              <label for="phone" class="text-dark font-weight-bold mb-0">Sími:</label>
              <b-form-input
                type="text"
                v-model="companyInfo.formData.phone"
                id="phone"
                oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                maxlength="8"
                @input="() => validateCompanyInfo('phone', companyInfo.formData.phone)"
                :class="companyInfo.formData.validate.phone === false && 'invalid-input'"
              />
              <label class="invalid-label" v-if="companyInfo.formData.validate.phone === false">Símanúmer er ekki gilt.</label>
            </div>
            <div>
              <label for="email" class="text-dark font-weight-bold mb-0">Netfang:</label>
              <b-form-input
                type="email"
                v-model="companyInfo.formData.email"
                id="email"
                @input="() => validateCompanyInfo('email', companyInfo.formData.email)"
                :class="companyInfo.formData.validate.email === false && 'invalid-input'"
              />
              <label class="invalid-label" v-if="companyInfo.formData.validate.email === false">Netfang er ekki gilt.</label>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex mb-1 justify-content-end pr-0 col-12">
            <b-button @click="editCompanyInfoToggle" variant="primary" class="button-radius">Breyta upplýsingum um vinnustað</b-button>
          </div>
          <div class="border p-4 col-12">
            <p>
              <label class="text-dark font-weight-bold mb-0">Vinnustaður:</label> {{ this.companyInfo.name }} (kt.
              {{ this.companyInfo.nationalId }})
            </p>
            <p><label class="text-dark font-weight-bold mb-0">Heimilisfang:</label> {{ this.companyInfo.address ?? '' }}</p>
            <p><label class="text-dark font-weight-bold mb-0">Póstnúmer:</label> {{ this.companyInfo.zipcode ?? '' }}</p>
            <p><label class="text-dark font-weight-bold mb-0">Sími:</label> {{ this.companyInfo.phone ?? '' }}</p>
            <p><label class="text-dark font-weight-bold mb-0">Netfang:</label> {{ this.companyInfo.email ?? '' }}</p>
          </div>
        </div>
      </div>
      <!-- Search bar for MMS -->
      <div v-if="loggedInUser.is_admin === 1" style="padding: 10px 0px 10px 0px">
        <b-form-input
          id="search"
          name="search"
          type="text"
          placeholder="Leita að umsókn eftir vinnustað, kennitölu eða umsóknarnúmeri"
          @input="searchApplication"
        />
      </div>
      <div v-if="this.noApprovedApplications">
        <p class="text-dark font-weight-bold" style="font-size: 1rem">Eingöngu samþykktar umsóknir koma fram hér</p>
      </div>
      <div v-else>
        <div v-for="(application, applicationIndex) in applicationList" :key="applicationIndex" class="mb-4">
          <h4><label class="text-dark font-weight-bold">Vinnustaður:</label> {{ application.name }}</h4>
          <h4><label class="text-dark font-weight-bold">Kennitala:</label> {{ application.nationalId }}</h4>
          <div style="display: flex; align-items: center; justify-content: space-between">
            <h4><label class="text-dark font-weight-bold">Umsókn:</label> {{ application.application_id }}</h4>
            <label v-if="application.newApplicationWaitingApprovalId" class="invalid-label font-italic">
              Bíður samþykkis: {{ application.newApplicationWaitingApprovalId }}
            </label>
            <span><label class="text-dark font-weight-bold">Síðast breytt:</label> {{ convertDate(application.date) }}</span>
            <div v-if="editingApplicationId === applicationIndex">
              <b-button @click="saveApplicationChanges(application.application_id)" variant="primary" class="mr-1 button-radius">
                Vista breytingar
              </b-button>
              <b-button @click="toggleViewEdit(applicationIndex)" variant="primary" class="cancel-button button-radius">
                Hætta við breytingar
              </b-button>
            </div>
            <div v-else>
              <b-button @click="toggleViewEdit(applicationIndex)" variant="primary" class="button-radius" v-if="editApplication">
                Breyta upplýsingum
              </b-button>
            </div>
          </div>
          <b-card v-for="(profession, index) in application.professions" :key="index">
            <div v-if="editingApplicationId === applicationIndex">
              <div style="display: flex; flex-wrap: wrap; align-items: left; justify-content: space-between">
                <h4 style="font-weight: bold; padding-top: 10px">{{ profession.name }}</h4>
              </div>
              <div>
                <div style="display: flex; align-items: flex-end; grid-gap: 10px">
                  <h5>1. Meistari</h5>
                  <b-button
                    v-if="!editMasterProfessionIds.includes(profession.profession_id)"
                    @click="changeMaster(profession.profession_id)"
                    variant="primary"
                    class="button-radius"
                  >
                    Skipta um meistara
                  </b-button>
                </div>
              </div>
              <div>
                <b-table
                  small
                  :items="filterEditSupervisorTables(editApplicationMaster, profession, true)"
                  :fields="editSupervisorDataColumnsMaster"
                >
                  <template #cell(nationalId)="data">
                    <b-form-input
                      v-if="editingMasterKeys.includes(data.item.key)"
                      :class="data.item.validate.nationalId === false && 'invalid-input'"
                      v-model="data.value"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="11"
                      @input="() => editSupervisorField(data.item.key, data.field.key, data.value, true)"
                    />
                    <p v-else>{{ data.value }}</p>
                    <label class="invalid-label" v-if="data.item.validate.nationalId === false">Kennitala er ekki gilt.</label>
                  </template>
                  <template #cell(name)="data">
                    <b-form-input
                      v-if="editingMasterKeys.includes(data.item.key)"
                      :class="data.item.validate.name === false && 'invalid-input'"
                      v-model="data.value"
                      @input="() => editSupervisorField(data.item.key, data.field.key, data.value, true)"
                      disabled
                    />
                    <p v-else>{{ data.value }}</p>
                    <label class="invalid-label" v-if="data.item.validate.name === false">Nafn er ekki gilt.</label>
                  </template>
                  <template #cell(phone)="data">
                    <b-form-input
                      :class="data.item.validate.phone === false && 'invalid-input'"
                      v-model="data.value"
                      oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                      maxlength="8"
                      @input="() => editSupervisorField(data.item.key, data.field.key, data.value, true)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.phone === false">Símanúmer er ekki gilt.</label>
                  </template>
                  <template #cell(email)="data">
                    <b-form-input
                      :class="data.item.validate.email === false && 'invalid-input'"
                      v-model="data.value"
                      @input="() => editSupervisorField(data.item.key, data.field.key, data.value, true)"
                    />
                    <label class="invalid-label" v-if="data.item.validate.email === false">Netfang er ekki gilt.</label>
                  </template>
                  <template #cell(files)="data">
                    <div v-if="!editingMasterKeys.includes(data.item.key)">
                      <div v-for="(file, index) in data.item.files" :key="`f_` + index">
                        <span style="vertical-align: middle" v-b-tooltip.hover :title="getTitle(file)">{{ file.name | limitTo('12') }}</span>
                        <button
                          class="btn btn-sm"
                          @click.prevent="download('file', file.aws_file, data.item.id, file.name)"
                          v-b-tooltip.hover
                          title="Hlaða niður skjali"
                        >
                          <i class="fa fa-fw fa-download"></i>
                        </button>
                      </div>
                    </div>
                    <b-button
                      v-else
                      class="btn btn-sm"
                      :class="{ 'btn-success': fileUploaded(data.item) }"
                      :disabled="checkUploadFileLock(data.item) || fileUploaded(data.item)"
                      title="Bæta við gögnum"
                      @click.prevent="applicationFileAdd(data.item.key)"
                      v-b-tooltip.hover
                    >
                      <i class="fa fa-fw fa-lock" v-if="checkUploadFileLock(data.item)"></i>
                      <i class="fa fa-fw fa-check" v-else-if="fileUploaded(data.item)"></i>
                      <i class="fa fa-fw fa-plus-circle" v-else></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <h5>Tilsjónaraðilar</h5>
              <b-table
                v-if="filterEditSupervisorTables(editApplicationExtraSupervisors, profession).length > 0"
                small
                :items="filterEditSupervisorTables(editApplicationExtraSupervisors, profession)"
                :fields="editSupervisorDataColumns"
              >
                <template #cell(nationalId)="data">
                  <b-form-input
                    :class="data.item.validate.nationalId === false && 'invalid-input'"
                    v-model="data.value"
                    oninput="this.value = this.value.replace(/[^0-9-]/g, '');"
                    maxlength="11"
                    @input="() => editSupervisorField(data.item.key, data.field.key, data.value, false)"
                  />
                  <label class="invalid-label" v-if="data.item.validate.nationalId === false">Kennitala er ekki gilt.</label>
                </template>
                <template #cell(name)="data">
                  <b-form-input
                    :class="data.item.validate.name === false && 'invalid-input'"
                    v-model="data.value"
                    @input="() => editSupervisorField(data.item.key, data.field.key, data.value, false)"
                    disabled
                  />
                  <label class="invalid-label" v-if="data.item.validate.name === false">Nafn er ekki gilt.</label>
                </template>
                <template #cell(phone)="data">
                  <b-form-input
                    :class="data.item.validate.phone === false && 'invalid-input'"
                    v-model="data.value"
                    oninput="this.value = this.value.replace(/[^0-9-]/g, '')"
                    maxlength="8"
                    @input="() => editSupervisorField(data.item.key, data.field.key, data.value, false)"
                  />
                  <label class="invalid-label" v-if="data.item.validate.phone === false">Símanúmer er ekki gilt.</label>
                </template>
                <template #cell(email)="data">
                  <b-form-input
                    :class="data.item.validate.email === false && 'invalid-input'"
                    v-model="data.value"
                    @input="() => editSupervisorField(data.item.key, data.field.key, data.value, false)"
                  />
                  <label class="invalid-label" v-if="data.item.validate.email === false">Netfang er ekki gilt.</label>
                </template>
                <template #cell(role)="data">
                  <b-form-select v-model="data.value" @input="() => editSupervisorField(data.item.key, data.field.key, data.value, false)">
                    <option :value="roles.extraMasterRoleString">{{ roles.extraMasterRoleString }}</option>
                    <option :value="roles.journeymanRoleString">{{ roles.journeymanRoleString }}</option>
                    <option :value="roles.monitorRoleString">{{ roles.monitorRoleString }}</option>
                  </b-form-select>
                </template>
                <template #cell(files)="data">
                  <div v-if="data.item.original">
                    <div v-for="(file, index) in data.item.files" :key="`f_` + index">
                      <span style="vertical-align: middle" v-b-tooltip.hover :title="getTitle(file)">{{ file.name | limitTo('12') }}</span>
                      <button
                        class="btn btn-sm"
                        @click.prevent="download('file', file.aws_file, data.item.id, file.name)"
                        v-b-tooltip.hover
                        title="Hlaða niður skjali"
                      >
                        <i class="fa fa-fw fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <b-button
                    v-else
                    class="btn btn-sm"
                    :class="{ 'btn-success': fileUploaded(data.item) }"
                    :disabled="checkUploadFileLock(data.item) || fileUploaded(data.item)"
                    title="Bæta við gögnum"
                    @click.prevent="applicationFileAdd(data.item.key, true)"
                    v-b-tooltip.hover
                  >
                    <i class="fa fa-fw fa-lock" v-if="checkUploadFileLock(data.item)"></i>
                    <i class="fa fa-fw fa-check" v-else-if="fileUploaded(data.item)"></i>
                    <i class="fa fa-fw fa-plus-circle" v-else></i>
                  </b-button>
                </template>
                <template #cell(actions)="data">
                  <b-button-group>
                    <b-button class="btn btn-sm" @click="removeSupervisor(data.item.key)"> <i class="fa fa-fw fa-trash"></i></b-button>
                  </b-button-group>
                </template>
              </b-table>
              <div class="m-0 mt-2" style="display: flex; flex: 1; place-content: flex-end">
                <b-button @click="addNewSupervisor(profession)">Bæta við tilsjónaraðila</b-button>
              </div>
            </div>
            <div v-else>
              <div style="display: flex; align-items: center; justify-content: space-between">
                <h4 class="text-dark font-weight-bold">{{ profession.name }}</h4>
              </div>
              <div>Nemenda pláss í boði: {{ profession.slots }}</div>
              <br />
              <h5>Tilsjónaraðilar</h5>
              <b-table
                primary-key="displayTable"
                small
                striped
                hover
                :items="filterSupervisorToProfession(application, profession)"
                :fields="supervisorDataColumns"
              >
                <template #cell(files)="data">
                  <div v-for="(file, index) in data.item.files" :key="`f_` + index">
                    <span style="vertical-align: middle" v-b-tooltip.hover :title="getTitle(file)">{{ file.name | limitTo('12') }}</span>
                    <button
                      class="btn btn-sm"
                      @click.prevent="download('file', file.aws_file, data.item.id, file.name)"
                      v-b-tooltip.hover
                      title="Hlaða niður skjali"
                    >
                      <i class="fa fa-fw fa-download"></i>
                    </button>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
    <div class="my-2">
      <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
    </div>
    <div class="my-2">
      <application-file-modal ref="applicationFileAdd" @uploaded-files="fileAddSuccess"></application-file-modal>
    </div>
  </page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import myPage from '@/api/myPage';
import application from '@/api/application';
import Pagination from '@/components/layout/Pagination.vue';
import {
  paginationMixin,
  parsePagination,
  randomId,
  roleMap,
  masterRoleString,
  journeymanRoleString,
  monitorRoleString,
  extraMasterRoleString,
  debounce,
} from '@/utils';
import { formatNationalId, formatPhoneNumber, removeHyphen } from '@/utils/formatters';
import {
  validateNationalId,
  validatePhoneNumber,
  validateEmail,
  validateStringMinMaxLength,
  fetchNameFromNationalRegistry,
  validateAreaCode,
} from '@/utils/validators';

import moment from 'moment';
import ApplicationFileModal from '@/components/application/Modal/ApplicationFileModal.vue';
import { DATE_TIME_FORMAT } from '@/config';

moment.locale('is');

export default {
  name: 'my-page',
  mixins: [paginationMixin],
  components: {
    Pagination,
    ApplicationFileModal,
  },
  data() {
    return {
      loading: false,
      searchTerm: '',
      noApprovedApplications: false,
      applicationList: [],
      page: null,
      pagination: null,
      editMaster: false,
      editMasterProfessionIds: [],
      editApplication: true,
      editCompanyInfo: false,
      roles: {
        masterRoleString,
        journeymanRoleString,
        monitorRoleString,
        extraMasterRoleString,
      },
      workplace: {
        id: null,
        nationalId: null,
        name: null,
        phone: null,
        email: null,
        staff: [],
        professions: [],
      },
      companyInfo: {
        nationalId: '',
        name: '',
        address: '',
        zipcode: '',
        phone: '',
        email: '',
        formData: {
          name: '',
          address: '',
          zipcode: '',
          phone: '',
          email: '',
          validate: {
            name: null,
            address: null,
            zipcode: null,
            phone: null,
            email: null,
          },
        },
      },
      editingApplicationId: null,
      supervisorDataColumns: [
        { key: 'role', label: 'Hlutverk' },
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Email' },
        { key: 'files', label: 'Gögn' },
      ],
      editSupervisorDataColumns: [
        { key: 'role', label: 'Hlutverk' },
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Email' },
        { key: 'files', label: 'Gögn' },
        { key: 'actions', label: 'Eyða' },
      ],
      editSupervisorDataColumnsMaster: [
        { key: 'nationalId', label: 'Kennitala' },
        { key: 'name', label: 'Nafn' },
        { key: 'phone', label: 'Símanúmer' },
        { key: 'email', label: 'Email' },
        { key: 'files', label: 'Gögn' },
      ],
      editApplicationMaster: [],
      editApplicationExtraSupervisors: [],
      hasFiles: [],
      editingMasterKeys: [],
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    convertDate(time) {
      return moment(time).format('LL'); // 1. júní 2023
    },
    fileAddSuccess(supervisorFiles) {
      this.hasFiles.push(supervisorFiles);
    },
    getCompanyInfo() {
      if (this.workplace) {
        this.companyInfo.name = this.workplace.name;
        this.companyInfo.nationalId = this.workplace.nationalId;
        this.companyInfo.address = this.workplace.address;
        this.companyInfo.zipcode = this.workplace.zipcode;
        this.companyInfo.phone = this.workplace.phone;
        this.companyInfo.email = this.workplace.email;

        // formData initialized with workplace data
        this.companyInfo.formData.name = this.workplace.name;
        this.companyInfo.formData.address = this.workplace.address;
        this.companyInfo.formData.zipcode = this.workplace.zipcode;
        this.companyInfo.formData.phone = this.workplace.phone;
        this.companyInfo.formData.email = this.workplace.email;
        return;
      }
      const firstAcceptedApplication = this.applicationList.find((_) => _.status === 2);
      if (firstAcceptedApplication) {
        this.companyInfo.name = firstAcceptedApplication.name;
        this.companyInfo.nationalId = firstAcceptedApplication.nationalId;
        this.companyInfo.phone = firstAcceptedApplication.phone;
        this.companyInfo.email = firstAcceptedApplication.email;
      } else {
        this.noApprovedApplications = true;
      }
    },
    editCompanyInfoToggle() {
      this.editCompanyInfo = !this.editCompanyInfo;
    },
    async editCompanyInfoSave() {
      if (
        this.companyInfo.formData.validate.name === false ||
        this.companyInfo.formData.validate.address === false ||
        this.companyInfo.formData.validate.zipcode === false ||
        this.companyInfo.formData.validate.phone === false ||
        this.companyInfo.formData.validate.email === false
      ) {
        this.displayError('Vinsamlegast lagaðu villur til að breyta vinnustaðar upplýsingum.');
        return;
      }
      try {
        const response = await myPage.editWorkplace({
          name: this.companyInfo.formData.name,
          address: this.companyInfo.formData.address,
          zipcode: this.companyInfo.formData.zipcode,
          phone: removeHyphen(this.companyInfo.formData.phone),
          email: this.companyInfo.formData.email,
        });
        if (response.status === 200) {
          this.editCompanyInfo = false;
          this.displaySuccess('Vinnustaðar upplýsingar hafa verið uppfærðar.');
          this.companyInfo.name = this.companyInfo.formData.name;
          this.companyInfo.address = this.companyInfo.formData.address;
          this.companyInfo.zipcode = this.companyInfo.formData.zipcode;
          this.companyInfo.phone = this.companyInfo.formData.phone;
          this.companyInfo.email = this.companyInfo.formData.email;
        }
      } catch {
        this.displayError('Ekki tókst að uppfæra vinnustaðar upplýsingar. Vinsamlegast reyndu aftur.');
      }
    },
    async applicationFileAdd(key, extra = false) {
      let newSupervisorData;
      let applicationId;
      if (extra) {
        const index = this.editApplicationExtraSupervisors.findIndex((row) => row.key === key);
        newSupervisorData = this.editApplicationExtraSupervisors[index];
        applicationId = this.applicationList[this.editingApplicationId].application_id;
      } else {
        const index = this.editApplicationMaster.findIndex((row) => row.key === key);
        newSupervisorData = this.editApplicationMaster[index];
        applicationId = this.newApplicationId;
      }
      const newSupervisor = {
        applicationId,
        ssn: removeHyphen(newSupervisorData.nationalId),
        name: newSupervisorData.name,
        phone_number: removeHyphen(newSupervisorData.phone),
        email: newSupervisorData.email,
        role: roleMap[newSupervisorData.role],
        professionId: newSupervisorData.profession_id,
      };
      const response = await application.supervisorList(newSupervisor);
      const newSupervisorId = response.data.supervisorId;
      newSupervisor.id = newSupervisorId;
      newSupervisorData.id = newSupervisorId;
      this.$refs.applicationFileAdd.show([newSupervisor], applicationId);
    },
    checkUploadFileLock(item) {
      if (
        validateNationalId(item?.nationalId) &&
        validateStringMinMaxLength(item.name, 1, 100) &&
        validatePhoneNumber(item?.phone?.toString()) &&
        validateEmail(item?.email)
      ) {
        return false;
      }
      return true;
    },
    fileUploaded(item) {
      if (this.hasFiles.length > 0) {
        return this.hasFiles.some((file) => file.supervisorId === item.id);
      }
      return false;
    },
    sortByMaster(a, b) {
      if (a.role === masterRoleString) {
        return -1;
      }
      if (a.role > b.role) {
        return 1;
      }
      return 0;
    },
    filterEditSupervisorTables(supervisors, profession, isMaster = false) {
      if (isMaster) {
        return supervisors.filter(
          (item) =>
            item.is_deleted !== 1 &&
            item.role === masterRoleString &&
            (item.profession_id === profession.profession_id || item.profession_id === null),
        );
      }
      return supervisors.filter(
        (item) =>
          item.is_deleted !== 1 &&
          item.role !== masterRoleString &&
          (item.profession_id === profession.profession_id || item.profession_id === null),
      );
    },
    filterSupervisorToProfession(applicationDetails, profession) {
      if (applicationDetails.professions.length === 1) {
        return applicationDetails.supervisors
          .filter(
            (row) => row.is_deleted !== 1 && (row.nationalId !== null || row.name !== null || row.phone !== null || row.email !== null),
          )
          .sort(this.sortByMaster);
      }
      return applicationDetails.supervisors
        .filter(
          (row) =>
            row.is_deleted !== 1 &&
            row.profession_id === profession.profession_id &&
            (row.nationalId !== null || row.name !== null || row.phone !== null || row.email !== null),
        )
        .sort(this.sortByMaster);
    },
    async download(type, id, supervisorId, filename) {
      try {
        if (type === 'file') {
          application.supervisorFileDownload(id, supervisorId, filename);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.status}) kom upp við að sækja mynd. Vinsamlegast reyndu aftur.`);
      }
    },
    getTitle(item) {
      return `${item.name} - ${moment(item.date_created).format(DATE_TIME_FORMAT)}`;
    },
    async loadWorkplace() {
      try {
        const response = await myPage.getWorkplace();
        const workplaceData = response.data;
        this.workplace = {
          id: workplaceData.id,
          nationalId: formatNationalId(workplaceData.ssn),
          name: workplaceData.name,
          address: workplaceData.address,
          zipcode: workplaceData.zipcode,
          phone: formatPhoneNumber(workplaceData?.phone?.toString()),
          email: workplaceData.email,
          staff: workplaceData.staff.map((employee) => {
            return {
              id: employee.id,
              nationalId: formatNationalId(employee.ssn),
              name: employee.name,
              role: employee.role,
              email: employee.email,
              phone: formatPhoneNumber(employee?.phone?.toString()),
            };
          }),
          professions: workplaceData.professions,
        };
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.status}) kom upp við að sækja vinnustaði. Vinsamlegast reyndu aftur.`);
      }
    },
    toggleViewEdit(index) {
      if (this.editingApplicationId === index) {
        this.editMaster = false;
        this.editingApplicationId = null;
        this.editMasterProfessionIds = [];
        this.editApplicationMaster = [];
        this.editApplicationExtraSupervisors = [];
        this.loadList();
        return;
      }
      this.editApplicationMaster = this.applicationList[index].supervisors
        .filter((row) => row.is_deleted !== 1 && row.role === masterRoleString)
        .map((row) => {
          return {
            key: randomId(),
            id: row.id,
            name: row.name,
            phone: row.phone,
            role: row.role,
            nationalId: row.nationalId,
            email: row.email,
            profession_id: row.profession_id,
            files: row.files,
            is_deleted: row.is_deleted,
            original: true,
            validate: {
              nationalId: null,
              name: null,
              phone: null,
              email: null,
            },
          };
        });
      this.editApplicationExtraSupervisors = this.applicationList[index].supervisors
        .filter((row) => row.is_deleted !== 1 && row.role !== masterRoleString)
        .map((row) => {
          return {
            key: randomId(),
            id: row.id,
            name: row.name,
            phone: row.phone,
            role: row.role,
            nationalId: row.nationalId,
            email: row.email,
            profession_id: row.profession_id,
            files: row.files,
            is_deleted: row.is_deleted,
            original: true,
            validate: {
              nationalId: null,
              name: null,
              phone: null,
              email: null,
            },
          };
        });
      this.editingApplicationId = index;
    },
    async changeMaster(professionId) {
      if (!this.editMaster) {
        await this.initApplication();
      }
      // remove all supervisors for this profession or null profession
      this.editApplicationMaster = this.editApplicationMaster.filter(
        (row) => row.profession_id !== professionId && row.profession_id !== null,
      );
      const newMasterKey = randomId();
      this.editApplicationMaster.push({
        key: newMasterKey,
        id: null,
        name: '',
        phone: '',
        role: masterRoleString,
        nationalId: '',
        email: '',
        profession_id: professionId,
        new_application_id: this.newApplicationId,
        validate: {
          nationalId: null,
          name: null,
          phone: null,
          email: null,
        },
      });
      this.editMasterProfessionIds.push(professionId);
      this.editingMasterKeys.push(newMasterKey);
      this.editMaster = true;
    },
    ...mapActions(['displayError', 'displaySuccess']),
    async loadList(page = null, limit = null, search = null) {
      try {
        const isAdmin = this.loggedInUser.is_admin === 1;
        const arbitraryLimit = 10000;
        const allApplicationsResponse = await application.myApplications(null, arbitraryLimit, isAdmin ? null : 1, 3, search); // meCompany: 1, filterStatus: 3 (unprocessed)
        const allApplications = allApplicationsResponse.data.items ?? [];
        const parentApplications = allApplications.filter((app) => app.parent_application_id !== null && app.status === 3); // status: 3 (unprocessed)
        const response = await application.myApplications(page, limit, isAdmin ? null : 1, 2, search); // meCompany: 1, filterStatus: 2 (approved)
        const applicationList = response.data.items ?? [];
        if (applicationList.length === 0) {
          this.noApprovedApplications = true;
          return;
        }
        this.noApprovedApplications = false;
        this.applicationList = applicationList.map((app) => {
          const newApplicationWaitingApprovalId = parentApplications.find(
            (parentApp) => parentApp.parent_application_id === app.application_id,
          )?.application_id;
          return {
            id: app.id,
            application_id: app.application_id,
            name: app.name,
            nationalId: formatNationalId(app.ssn),
            address: app.address,
            zipcode: app.zipcode,
            email: app.email,
            phone: formatPhoneNumber(app?.mobile?.toString()),
            date: app.date,
            status: app.status,
            parentApplicationId: app.parent_application_id,
            professions: app.professions,
            newApplicationWaitingApprovalId,
            supervisors: app.supervisors.map((supervisor) => {
              return {
                id: supervisor.id,
                name: supervisor.name,
                nationalId: formatNationalId(supervisor.ssn),
                phone: formatPhoneNumber(supervisor?.mobile?.toString()),
                email: supervisor.email,
                is_deleted: supervisor.is_deleted,
                profession_id: supervisor.profession_id,
                role: supervisor.role,
                files: supervisor.files,
              };
            }),
          };
        });

        this.pagination = parsePagination(response.data.extra, response.data.items);
        this.getCompanyInfo();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async searchApplication(input) {
      this.searchTerm = input;
    },
    async initApplication() {
      try {
        const item = {
          ssn: removeHyphen(this.companyInfo.nationalId),
        };
        const response = await application.applicationCreate(item);
        this.newApplicationId = response.data.applicationId;
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur`);
      } finally {
        this.loading = false;
      }
    },
    addNewSupervisor(profession) {
      this.editApplicationExtraSupervisors.push({
        key: randomId(),
        id: null,
        name: null,
        phone: null,
        role: journeymanRoleString,
        nationalId: null,
        email: null,
        profession_id: profession.profession_id,
        files: null,
        is_deleted: null,
        validate: {
          nationalId: null,
          name: null,
          phone: null,
          email: null,
        },
      });
    },
    async editSupervisorField(key, field, value, isMaster) {
      let supervisorData;
      if (isMaster) {
        supervisorData = this.editApplicationMaster.find((row) => row.key === key);
      } else {
        supervisorData = this.editApplicationExtraSupervisors.find((row) => row.key === key);
      }

      let newValue = value;
      if (field === 'nationalId') {
        newValue = formatNationalId(newValue);
        // nationalid with hyphen
        if (newValue.length === 11) {
          const response = await fetchNameFromNationalRegistry(newValue);
          if (response.error) {
            supervisorData.name = '';
            supervisorData.validate.nationalId = false;
            return;
          }
          const { name } = response;
          supervisorData.name = name;
        }
      }
      if (field === 'phone') {
        newValue = formatPhoneNumber(newValue);
      }
      supervisorData[field] = newValue === '' ? null : newValue;
      this.validateInput(key, field, newValue, isMaster);
    },
    validateCompanyInfo(field, value) {
      if (field === 'name') {
        this.companyInfo.formData.validate.name = value?.length > 0 ?? false;
      } else if (field === 'address') {
        this.companyInfo.formData.validate.address = value?.length > 0 ?? false;
      } else if (field === 'zipcode') {
        this.companyInfo.formData.validate.zipcode = validateAreaCode(value);
      } else if (field === 'phone') {
        this.companyInfo.formData.phone = formatPhoneNumber(value?.toString());
        this.companyInfo.formData.validate.phone = validatePhoneNumber(value?.toString());
      } else if (field === 'email') {
        this.companyInfo.formData.validate.email = validateEmail(value);
      }
    },
    validateInput(key, field, value, master = false) {
      if (master) {
        const index = this.editApplicationMaster.findIndex((row) => row.key === key);
        if (field === 'nationalId') {
          this.editApplicationMaster[index].validate.nationalId = validateNationalId(value);
        } else if (field === 'name') {
          this.editApplicationMaster[index].validate.name = value?.length > 0 ?? false;
        } else if (field === 'phone') {
          this.editApplicationMaster[index].validate.phone = validatePhoneNumber(value?.toString());
        } else if (field === 'email') {
          this.editApplicationMaster[index].validate.email = validateEmail(value);
        }
        return;
      }
      const index = this.editApplicationExtraSupervisors.findIndex((row) => row.key === key);
      if (field === 'nationalId') {
        this.editApplicationExtraSupervisors[index].validate.nationalId = validateNationalId(value);
      } else if (field === 'name') {
        this.editApplicationExtraSupervisors[index].validate.name = value?.length > 0 ?? false;
      } else if (field === 'phone') {
        this.editApplicationExtraSupervisors[index].validate.phone = validatePhoneNumber(value?.toString());
      } else if (field === 'email') {
        this.editApplicationExtraSupervisors[index].validate.email = validateEmail(value);
      }
    },
    removeSupervisor(key) {
      const index = this.editApplicationExtraSupervisors.findIndex((row) => row.key === key);
      if (this.editApplicationExtraSupervisors[index].id) {
        this.editApplicationExtraSupervisors[index].is_deleted = 1;
      } else {
        this.editApplicationExtraSupervisors.splice(index, 1);
      }
      // refresh table
      this.editApplicationExtraSupervisors = [...this.editApplicationExtraSupervisors];
    },
    applicationValid() {
      let valid = true;
      this.editApplicationMaster.forEach((row) => {
        if (row.is_deleted !== 1) {
          this.validateInput(row.key, 'nationalId', row.nationalId, true);
          this.validateInput(row.key, 'name', row.name, true);
          this.validateInput(row.key, 'phone', row.phone?.toString(), true);
          this.validateInput(row.key, 'email', row.email, true);
          if (!row.validate.nationalId || !row.validate.name || !row.validate.phone || !row.validate.email) {
            valid = false;
          }
        }
      });
      this.editApplicationExtraSupervisors.forEach((row) => {
        if (row.is_deleted !== 1) {
          this.validateInput(row.key, 'nationalId', row.nationalId);
          this.validateInput(row.key, 'name', row.name);
          this.validateInput(row.key, 'phone', row.phone);
          this.validateInput(row.key, 'email', row.email);
          if (!row.validate.nationalId || !row.validate.name || !row.validate.phone || !row.validate.email) {
            valid = false;
          }
        }
      });
      return valid;
    },
    async saveApplicationChanges(applicationId) {
      if (!this.applicationValid()) {
        // display error toast
        const invalidApplicationError = 'Ekki tókst að vista umsókn vegna innsláttarvilla, vinsamlegast yfirfarið skjalið og reynið aftur.';
        this.displayError(invalidApplicationError);
        return;
      }
      if (this.editingMasterKeys.length > this.hasFiles.length) {
        // display error toast
        const invalidFilesError = 'Vantar gögn fyrir nýjann meistara, vinsamlegast yfirfarið skjalið og reynið aftur.';
        this.displayError(invalidFilesError);
        return;
      }
      const changedSupervisors = JSON.stringify(
        this.editApplicationExtraSupervisors
          .filter((row) => row.role !== null && row.nationalId !== null && row.name !== null)
          .concat(this.editApplicationMaster)
          .map((row) => {
            return {
              id: row.id,
              name: row.name,
              mobile: removeHyphen(row.phone),
              role: roleMap[row.role],
              ssn: removeHyphen(row.nationalId),
              email: row.email,
              new_application_id: row.new_application_id ?? null,
              profession_id: row.profession_id,
              is_deleted: row.is_deleted ?? null,
            };
          }),
      );
      const item = {
        masterChange: this.editMaster ? 1 : 0,
        newApplicationId: this.newApplicationId ?? null,
        supervisors: changedSupervisors,
      };
      await application
        .applicationEdit(applicationId, item)
        .then(() => {
          this.editingApplicationId = null;
          this.editApplicationExtraSupervisors = [];
          this.loadList();
        })
        .catch((error) => {
          console.error({ error });
        });
      this.editMaster = false;
      this.editMasterProfessionIds = [];
    },
  },
  watch: {
    searchTerm: debounce(function () {
      this.loadList(null, null, this.searchTerm);
    }, 500),
  },
  created() {
    this.loadWorkplace();
    this.loadList();
  },
};
</script>

<style lang="scss" scoped>
.invalid-label {
  color: indianred;
}
.invalid-input {
  color: indianred;
  border-color: indianred;
  outline-color: indianred;
}
.invalid-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 92, 92, 0.5);
}
.button-radius {
  border-radius: 10px;
}
.cancel-button {
  background-color: rgb(168, 62, 62);
}
</style>
