<template>
  <div>
    <div>
      <h5 class="grayBox">Starfalýsing</h5>
      <div class="box" style="padding: 20px">
        <span v-html="description"></span>
        <span v-if="description == null || description.length == 0">Starfalýsing ekki skráð</span>
      </div>
      <br />
      <div>
        <h5 class="grayBox">Hæfnikröfur starfs</h5>
        <div class="box" style="padding: 20px">
          <b-row>
            <b-col>
              <div class="d-inline">
                <div v-for="(category, index) in categoryList" :key="index" class="pb-2">
                  <div
                    v-for="(competence, compIdx) in category.competence"
                    :key="compIdx"
                    class="pb-2"
                    :class="{ light_gray: compIdx % 2 === 0, white: compIdx % 2 !== 0 }"
                  >
                    <div>
                      {{ index + 1 }}.{{ compIdx + 1 }}. {{ competence.name
                      }}<strong class="cursorPointer" v-b-popover.hover.html.left="getFactors(competence)" title="Verkþættir"
                        >({{ competence.connected.length }})</strong
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!loading && categoryList.length === 0">
                <i>Engar hæfnikröfur skráðar</i>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import core from '@/api/core';

export default {
  name: 'overview',
  props: ['professionId', 'versionId', 'description'],
  components: {},
  data() {
    return {
      loading: true,
      connect: false,
    };
  },
  methods: {
    async loadCompetenceCategoryList() {
      this.loading = true;
      this.categoryList = {};
      try {
        const response = await core.competenceListLogin(this.professionId, this.versionId);
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getFactors(competence) {
      let html = '<ul>';
      competence.connected.forEach((c) => {
        html += `<li>${c.factor.category_name}:  ${c.factor.name}</li>`;
      });
      html += '</ul>';

      if (competence.connected.length === 0) {
        html = '<i>Engir tengdir</i>';
      }
      return html;
    },
  },
  created() {
    this.loadCompetenceCategoryList();
  },
};
</script>
<style>
.box {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
h5.grayBox {
  background-color: #868e96;
  color: #fff !important;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0px;
  font-size: 1.03125rem;
}
.light_gray {
  background-color: #f1f1f1;
  padding: 5px 5px 5px 5px;
}
.white {
  background-color: white;
  padding: 5px 5px 5px 5px;
}
.cursorPointer {
  float: right;
  cursor: pointer;
}
</style>
