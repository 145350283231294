<template>
  <div id="app">
    <router-view />
    <toast position="ne"></toast>
  </div>
</template>

<script>
import { Toast } from 'vuex-toast';
import { mapActions } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'App',
  components: {
    Toast,
  },
  data() {
    return {
      pingTimeout: null,
    };
  },
  methods: {
    async ping() {
      try {
        // Sækja uppl. innskráningar.
        const token = auth.getToken();

        if (token) {
          const response = await auth.ping();
          if (response.successful && response.timeleft) {
            auth.setTokenExpire(response.timeleft);
          }
        }
      } catch (e) {
        this.$log.debug(e);
        auth.removeToken();
        this.doClean();
        this.$router.push({ name: 'Login', query: { timeout: 'true' } });
      } finally {
        this.pingTimeout = setTimeout(() => {
          this.ping();
        }, 120000);
      }
    },
    ...mapActions(['doClean']),
  },
  created() {
    this.ping();
  },
  beforeDestroy() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
  },
};
</script>

<style lang="scss">
@import '~vuex-toast/dist/vuex-toast';
@import './style/bootstrap';
@import './style/main';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0px;
}

.toast-type-success {
  background-color: $success;
}

.toast-type-danger {
  background-color: $danger;
}
</style>
