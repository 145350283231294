<template>
  <div>
    <div>
      <p><strong>Vinsamlegast fylltu út eftirfarandi upplýsingar fyrir:</strong></p>
    </div>
    <div>
      {{ loggedInUser.name }}
      <div>
        <i style="font-size: 11px; color: gray">{{ loggedInUser.ssn }}</i>
      </div>
    </div>
    <br />
    <b-form-group label="Símanúmer:" label-for="mobile" :state="submitted && errors.has('mobile') ? false : null">
      <b-form-input
        id="mobile"
        name="mobile"
        type="tel"
        requried
        :state="submitted && errors.has('mobile') ? false : null"
        v-model="form.mobile"
        v-validate="'required'"
        data-vv-as="simanumer"
      >
      </b-form-input>
    </b-form-group>
    <b-form-group label="Netfang:" label-for="email">
      <b-form-input
        id="email"
        name="email"
        type="email"
        required
        :state="submitted && errors.has('email') ? false : null"
        v-validate="'required'"
        data-vv-as="netfang"
        v-model="form.email"
      >
      </b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'user-auto-update-form',
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      form: {
        mobile: null,
        email: null,
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        try {
          await auth.userUpdate(this.loggedInUser.id, { mobile: this.form.mobile, email: this.form.email });
          this.displaySuccess(`Upplýsingar fyrir „${this.loggedInUser.name}“ hafa verið uppfærðar.`);
          this.$emit('successful');
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style></style>
