<template>
  <b-modal size="xl" :no-close-on-backdrop="true" ref="viewModal" :hide-footer="true">
    <b-card class="careerbook-tabs" :class="{ 'careerbook-tabs-fixed': tabsFixed }">
      <b-row class="d-lg-none">
        <b-col>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Hæfnikröfur</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 3" :active="tabIndex === 3" class="first">Verkflokkar</b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-row cols="2" class="d-none d-sm-none d-md-none d-lg-block">
        <b-col>
          <span ref="tabs"></span>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Hæfnikröfur</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 1" :active="tabIndex === 1">Verkflokkar</b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-col sm="12" md="12" lg="12" v-if="profession" style="padding: 0px">
        <div>
          <h3 class="text-center">{{ profession.professionName }}</h3>
          <div class="text-center" v-if="version">
            <i>Útgáfa {{ version.version }}</i>
          </div>
        </div>
        <competence v-show="tabIndex === 0" :professionId="profession.id" :versionId="version.id" :description="version.description">
        </competence>
        <category-list v-show="tabIndex === 1" :professionId="profession.id" :versionId="version.id"></category-list>
      </b-col>
    </b-card>
  </b-modal>
</template>

<script>
import Competence from '@/components/login/version/Overview.vue';
import CategoryList from '@/components/login/version/CategoryList.vue';

export default {
  name: 'careerbook-version-modal-login',
  components: {
    Competence,
    CategoryList,
  },
  data() {
    return {
      version: null,
      profession: null,
      professionDescription: '',
      tabIndex: 0,
      tabsFixed: false,
    };
  },
  methods: {
    show(profession = null, version = null) {
      this.profession = profession;
      this.version = version;
      this.$refs.viewModal.show();
    },
  },
  created() {},
};
</script>
<style lang="scss">
.mobile-navbar {
  li.nav-item {
    a.nav-link {
      padding: 0.5rem 0.5rem;
    }
  }
}
.careerbook-tabs {
  .nav-tabs {
    display: none;
  }

  .nav {
    border-bottom: 0.5px solid #f1f1f1;
    margin-bottom: 10px;
  }

  li.nav-item {
    &.first {
      a.nav-link {
        padding: 10px 5px 10px 5px;
      }
    }

    a.nav-link {
      color: inherit;
      margin: 0px 3px 3px 0px;
      padding: 10px 10px 10px 10px;
      border: 1px solid #ffffff;
      margin-bottom: -1px;

      &.active {
        color: inherit;
        font-weight: bold;
        border: 1pxsolid #ffffff;
        border-bottom: 1px solid #ffffff !important;
        background-color: #ffffff;
      }
      &:hover {
        border: 1px solid #ffffff;
        background-color: #ffffff;
      }
    }
  }

  &.careerbook-tabs-fixed {
    .careerbook-nav {
      position: fixed;
      top: 20px;
    }
  }
}
h3 {
  font-weight: 200;
}
</style>
