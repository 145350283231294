<template>
  <div>
    <b-modal
      id="userAutoUpdateModal"
      title="Uppfæra upplýsingar"
      size="md"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      cancel-disabled
      hide-header
      no-close-on-esc
      @ok.prevent="handleCreate"
      ref="autoUpdateModal"
    >
      <user-auto-update-form ref="userAutoUpdateForm" @successful="successful"></user-auto-update-form>
    </b-modal>
  </div>
</template>

<script>
import UserAutoUpdateForm from '@/components/user/UserAutoUpdateForm.vue';

export default {
  name: 'user-auto-update-crud-modal',
  components: {
    UserAutoUpdateForm,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show() {
      this.$refs.autoUpdateModal.show();
    },
    handleCreate() {
      this.$refs.userAutoUpdateForm.validateBeforeSubmit();
    },
    successful() {
      this.$refs.autoUpdateModal.hide();
    },
  },
};
</script>

<style></style>
