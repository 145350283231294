<template>
  <page>
    <b-card>
      <b-row>
        <b-col>
          <span ref="tabs"></span>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <!-- <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Allir notendur</b-nav-item> -->
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-col sm="12" md="12" lg="12" style="padding: 0px">
        <all-users-list v-show="tabIndex === 0"></all-users-list>
      </b-col>
    </b-card>
  </page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';
import AllUsersList from '@/components/user/AllUsersList.vue';

import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'user-list',
  mixins: [paginationMixin],
  components: {
    AllUsersList,
  },
  data() {
    return {
      loading: false,
      userList: [],
      tabIndex: 0,
      page: null,
      pagination: null,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.pagination = null;
      this.userList = [];

      try {
        const response = await auth.userList(page, limit);
        this.userList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir notendur. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    ...mapActions(['displayError']),
  },
  created() {
    this.loadList();
  },
};
</script>
