<template>
  <div>
    <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
    <div v-else>
      <div v-if="categoryList.length == 0"><i>Engar niðurstöður fundust</i></div>
      <category-item
        v-for="(category, index) in categoryList"
        :key="index"
        :index="index"
        :item="category"
        :professionId="professionId"
        :not-collapsed="category.id === createdId"
      ></category-item>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import CategoryItem from '@/components/login/version/CategoryItem.vue';

export default {
  name: 'category-list',
  components: {
    CategoryItem,
  },
  props: ['professionId', 'versionId', 'id'],
  data() {
    return {
      loading: true,
      categoryList: null,
      createdId: null,
    };
  },
  methods: {
    async loadCategory() {
      this.loading = true;
      this.categoryList = {};
      try {
        const response = await core.factorCategoryList(this.professionId, this.versionId, true);
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkflokka. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.loadCategory();
  },
};
</script>
