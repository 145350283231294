/* eslint-disable import/prefer-default-export */

export const hyphenateInput = (input: string, index: number) => {
  // Hyphenates input string at index and returns it (unless input is less than or equal to index)
  // Example: hyphenateInput('123456789', 3) => '123-456789'
  if (!input) return '';
  if (input.length <= index) return input;
  if (input.includes('-')) return input;

  const firstPart = input.slice(0, index);
  const secondPart = input.slice(index);
  return `${firstPart}-${secondPart}`;
};

export const removeHyphen = (input: string) => {
  // Removes hyphen from input string and returns it
  // Example: removeHyphen('123-456789') => '123456789'
  if (!input) return '';
  return input.replace('-', '');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // Formats phone number to include hyphen
  // Example: formatPhoneNumber('1234567') => '123-4567'
  if (!phoneNumber) return '';
  return hyphenateInput(phoneNumber, 3).slice(0, 8);
};

export const formatNationalId = (nationalId: string) => {
  // Formats national ID to include hyphen
  // Example: formatNationalId('1234567890') => '123456-7890'
  if (!nationalId) return '';
  return hyphenateInput(nationalId, 6).slice(0, 11);
};
