<template>
  <div>
    <b-card header="Almennar upplýsingar" header-bg-variant="info" header-text-variant="white" header-class="h5" class="mt-3">
      <b-row>
        <b-col>
          <strong>Fyrirtæki</strong>
          <div>{{ applicationList.name }}</div>
        </b-col>
        <b-col>
          <strong>Kennitala</strong>
          <div>{{ applicationList.ssn }}</div>
        </b-col>
        <b-col>
          <strong>Símanúmer</strong>
          <div>{{ applicationList.mobile }}</div>
        </b-col>
        <b-col>
          <strong>Netfang</strong>
          <div>{{ applicationList.email }}</div>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Starfsgrein" header-bg-variant="info" header-text-variant="white" header-class="h5" class="mt-3">
      <b-row>
        <b-col>
          <table class="table table-sm">
            <thead>
              <tr>
                <th colspan="3">Starfsgrein</th>
                <th>Fjöldi plássa</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in applicationList.professions" :key="index">
                <td colspan="3">
                  {{ p.name }}
                  <br />
                  <br />
                </td>
                <td>{{ p.slots }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Tilsjónaraðili" header-bg-variant="info" header-text-variant="white" header-class="h5" class="mt-3">
      <b-row>
        <b-col>
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Hlutverk</th>
                <th>Tilsjónaraðili</th>
                <th>Kennitala</th>
                <th>Símanúmer</th>
                <th>Netfang</th>
                <th>
                  Gögn
                  <b-link
                    href
                    class="btn btn-sm"
                    title="Bæta við gögnum"
                    @click.prevent="applicationFileAdd(applicationList.supervisors, applicationList.application_id)"
                    v-b-tooltip.hover
                    ><i class="fa fa-fw fa-plus-circle"></i
                  ></b-link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(supervisor, index) in applicationList.supervisors.filter((supervisor) => supervisor.is_deleted != 1)" :key="index">
                <td>{{ supervisor.role }}</td>
                <td>{{ supervisor.name }}</td>
                <td>{{ supervisor.ssn }}</td>
                <td>{{ supervisor.mobile }}</td>
                <td>{{ supervisor.email }}</td>
                <td>
                  <div v-for="file in supervisor.files" :key="file.id">
                    <span style="vertical-align: middle" v-b-tooltip.hover :title="getTitle(file)">{{ file.name | limitTo('12') }}</span>
                    <button
                      class="btn btn-sm"
                      @click.prevent="download('file', file.aws_file, supervisor.id, file.name)"
                      v-b-tooltip.hover
                      title="Hlaða niður skjali"
                    >
                      <i class="fa fa-fw fa-download"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <application-file-modal ref="applicationFileAdd" @successful="applicationFileAddViewed"></application-file-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import application from '@/api/application';
import ApplicationFileModal from '@/components/application/Modal/ApplicationFileModal.vue';

export default {
  name: 'application-form',
  props: ['id'],
  components: {
    ApplicationFileModal,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      applicationList: [],
      sort: {
        files: 'name',
        images: 'name',
      },
    };
  },
  computed: {
    orderedFiles() {
      return [...this.applicationList.supervisors.files].sort((a, b) => {
        if (a[this.sort.files] < b[this.sort.files]) {
          return -1;
        }
        if (a[this.sort.files] > b[this.sort.files]) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    applicationFileAdd(supervisor, applicationId) {
      this.$refs.applicationFileAdd.show(supervisor, applicationId);
    },
    applicationFileAddViewed() {
      this.load();
    },
    async download(type, id, supervisorId, filename) {
      try {
        if (type === 'file') {
          application.supervisorFileDownload(id, supervisorId, filename);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.status}) kom upp við að sækja mynd. Vinsamlegast reyndu aftur.`);
      }
    },
    getTitle(item) {
      return `${item.name} - ${moment(item.date_created).format('DD.MM.YYYY HH:mm:ss')}`;
    },
    async load() {
      try {
        this.loading = true;
        this.applicationList = [];
        const response = await application.applicationDetail(this.id);
        this.applicationList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja lista yfir starfsgreinar.
                Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit(accepted) {
      try {
        this.loading = true;
        const item = {
          application_id: this.applicationList.application_id,
          status: accepted,
          name: this.applicationList.name,
          ssn: this.applicationList.ssn,
          mobile: this.applicationList.mobile,
          profession: JSON.stringify(this.applicationList.professions.map((l) => ({ id: l.id, slots: l.slots }))),
        };
        const response = await application.applicationHandle(item);
        if (response.status === 422) {
          this.loading = false;
          this.displayError(response.data.message);
          return;
        }
        if (accepted === true) {
          this.displaySuccess('Umsókn samþykkt');
          this.$emit('successful', { id: this.id });
        } else {
          this.displaySuccess('Umsókn höfnuð');
          this.$emit('successful', { id: this.id });
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að samþykkja umsókn.
              Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    validateBeforeReject() {
      this.displaySuccess('Umsókn höfnuð. TODO');
    },
    ...mapActions({
      displaySuccess: 'displaySuccess',
      displayError: 'displayError',
    }),
  },
  created() {
    this.load();
  },
};
</script>
