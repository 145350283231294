<template>
  <nav>
    <b-navbar :toggleable="true" class="small py-0 navbar-expand-sm" type="dark" variant="top">
      <b-container>
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right v-if="loggedInUser">
              <template slot="button-content">
                <span v-if="loggedInUser">{{ loggedInUser.name }}</span>
              </template>
              <b-dropdown-item :to="{ name: 'VersionListLogin' }">Útgáfur starfsgreina</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'WorkplaceList' }">Vinnustaðir</b-dropdown-item>
              <b-dropdown-item @click.prevent="logout()" href="#"> Útskrá </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <b-navbar toggleable="md" type="dark" variant="subtop">
      <b-container>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-navbar-brand :to="{ name: 'Index' }">
          <img src="@/assets/logo.png" style="height: 32px" />
          FERILBÓK BIRTINGASKRÁ
        </b-navbar-brand>
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="ml-auto" v-if="loggedInUser">
            <b-nav-item :to="{ name: 'Index' }" class="nav-item"> Mínar Umsóknir </b-nav-item>
            <b-nav-item v-if="loggedInUser.is_admin === 1" :to="{ name: 'MyPage' }" class="nav-item"> Umsjón </b-nav-item>
            <b-nav-item v-else :to="{ name: 'MyPage' }" class="nav-item"> Mitt svæði </b-nav-item>
            <b-nav-item v-if="loggedInUser.is_admin === 1" :to="{ name: 'ApplicationList' }" class="nav-item"> Yfirlit </b-nav-item>
            <b-nav-item v-if="loggedInUser.is_admin === 1" :to="{ name: 'UsersList' }" class="nav-item"> Notendur </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto" v-else>
            <b-nav-item :to="{ name: 'Login' }" class="nav-item"> Innskráning </b-nav-item>
            <b-nav-item-dropdown left>
              <template slot="button-content">
                <span>Upplýsingar</span>
              </template>
              <b-dropdown-item :to="{ name: 'VersionListLogin' }">Útgáfur starfsgreina</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'WorkplaceList' }">Vinnustaðir</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <br />
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'navbar',
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      auth.removeToken();
      this.doClean();
      this.$router.push({ name: 'Login', query: { logout: 'true' } });
    },
    ...mapState(['doClean']),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import '../../style/variables';

.nav-item {
  color: white;
}
</style>
