import core from '@/api/core';
import { removeHyphen } from '../formatters';

export const validateNationalId = (nationalId: string) => {
  if (!nationalId) return false;
  // Just checking for 10 digits
  let temp = nationalId;
  if (nationalId.includes('-')) {
    temp = nationalId.replace('-', '');
  }

  const regex = /^\d{10}$/;
  if (!regex.test(temp)) return false;
  return true;
};

export const validateEmail = (email: string) => {
  if (!email) return false;

  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!regex.test(email)) return false;
  return true;
};

export const validateStringMinMaxLength = (value: string, minLength: number = 0, maxLength: number) => {
  if (!value) return false;
  if (value.length < minLength || value.length > maxLength) return false;
  return true;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return false;
  // Just checking for 7 digits
  let temp = phoneNumber;
  if (phoneNumber.includes('-')) {
    temp = phoneNumber.replace('-', '');
  }

  const regex = /^\d{7}$/;
  if (!regex.test(temp)) return false;
  return true;
};

export const validateAreaCode = (areaCode: string) => {
  if (!areaCode) return false;
  // Just checking for 3 digits

  const regex = /^\d{3}$/;
  if (!regex.test(areaCode)) return false;
  return true;
};

export const fetchNameFromNationalRegistry = async (nationalId: string) => {
  if (!validateNationalId(nationalId)) {
    return { error: true, message: 'Kennitala er ekki gild', name: '' };
  }
  const response = await core.fetchNationalRegistryName(removeHyphen(nationalId));
  if (response.status !== 200) {
    return { error: true, message: 'Villa við að sækja nafn úr Þjóðskrá', name: '' };
  }
  return response.data;
};

export const fetchInfoFromNationalRegistry = async (nationalId: string) => {
  if (!validateNationalId(nationalId)) {
    return { error: true, message: 'Kennitala er ekki gild', name: '' };
  }
  const response = await core.fetchNationalRegistryInfo(removeHyphen(nationalId));
  if (response.status !== 200) {
    return { error: true, message: 'Villa við að sækja úr Þjóðskrá', name: '' };
  }
  return response.data;
};
