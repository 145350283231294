// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import VueLogger from 'vuejs-logger';
import { sync } from 'vuex-router-sync';

import vSelect from 'vue-select';
import VueMoment from 'vue-moment';

import moment from 'moment';

import Page from './components/layout/Page.vue';
import PageLogin from './components/layout/PageLogin.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import is from './locale/validation/is';

import './http';
import './scrollspy';

Vue.config.productionTip = false;

Validator.localize('is', is);

Vue.use(BootstrapVue);
Vue.component('v-select', vSelect);

Vue.use(VueMoment, {
  moment,
});
Vue.use(VeeValidate, {
  locale: 'is',
  fieldsBagName: 'veeFields',
  mode: 'eager',
});

Vue.use(VueLogger as any, {
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: 'debug',
  // optional : defaults to false if not specified
  stringifyArguments: false,
  // optional : defaults to false if not specified
  showLogLevel: true,
  // optional : defaults to false if not specified
  showMethodName: false,
  // optional : defaults to '|' if not specified
  separator: '|',
  // optional : defaults to false if not specified
  showConsoleColors: true,
});

Vue.filter('limitTo', (value: string | undefined, length: number) => {
  if (!value) return '';
  if (value.length <= length) return value;
  return `${value.substring(0, length)}..`;
});

Vue.component('page', Page);
Vue.component('page-login', PageLogin);

sync(store, router);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
