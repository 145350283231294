<template>
  <page>
    <b-card>
      <b-row>
        <b-col>
          <h3 class="mb-4 text-dark font-weight-bold">Yfirlit yfir umsóknir</h3>
        </b-col>
      </b-row>
      <div>
        <table class="table">
          <tr>
            <th style="padding: 10px 10px 10px 0px">
              <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir nafni eða kennitölu">
              </b-form-input>
            </th>
            <th style="padding: 10px 10px 10px 0px">
              <b-form-select id="status" name="status" v-model="searchStatus">
                <option :value="null">-- Veldu stöðu --</option>
                <option v-for="(status, index) in statusList" :key="index" :value="status">{{ status.name }}</option>
              </b-form-select>
            </th>
            <th style="padding: 10px 10px 10px 0px">
              <b-form-select id="zipcode" name="zipcode" v-model="searchZipCode">
                <option :value="null">-- Veldu póstnúmer --</option>
                <option v-for="(zipcode, index) in zipCodeList" :key="index" :value="zipcode">{{ zipcode.zipcode }}</option>
              </b-form-select>
            </th>
          </tr>
        </table>
        <table class="table table-hover">
          <thead>
            <tr>
              <!-- <th>Umsókn</th> -->
              <th></th>
              <th>Nafn</th>
              <th>Kennitala</th>
              <th>Starfsgrein</th>
              <th>Dags. umsóknar</th>
              <th>Staða umsóknar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="applicationList.length == 0">
              <td colspan="2">
                <i>Engar leitarniðurstöður fundust.</i>
              </td>
            </tr>
            <tr class="table-row-linkable" v-for="(application, index) in applicationList" :key="index">
              <td>
                {{ application.application_id }}
              </td>
              <td>
                <div>
                  {{ application.name }}
                </div>
                <div style="font-size: 12px">
                  <i> {{ application.address }} {{ application.zipcode }}</i>
                </div>
              </td>
              <td>
                {{ application.ssn }}
              </td>
              <td>
                <span v-for="(profession, index) in application.professions" :key="profession.id">
                  <span v-if="index != 0">, </span>{{ profession.name }}({{ profession.slots }})
                </span>
              </td>
              <td>
                {{ application.date | moment('DD.MM.YYYY') }}
              </td>
              <td v-if="application.status === 0">Í vinnslu</td>
              <td v-if="application.status === 1">Hafnað</td>
              <td v-if="application.status === 2">Samþykkt</td>
              <td v-if="application.status === 3">Óafgreidd</td>
              <td width="1">
                <b-link
                  class="btn btn-secondary pt-0 pb-0 pl-1 pr-1"
                  href
                  @click.prevent="applicationViewModal(application.id, application.status)"
                  v-b-tooltip.hover
                  title="Skoða umsókn"
                  ><i class="fa fa-search"></i
                ></b-link>
              </td>
              <td>
                <b-link
                  class="btn btn-secondary pt-0 pb-0 pl-1 pr-1"
                  v-b-tooltip.hover
                  title="Taka umsókn út í PDF"
                  @click="applicationPdf(application)"
                >
                  <i class="fa fa-fw fa-file-pdf-o"></i>
                </b-link>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
      </div>
    </b-card>
    <application-modal ref="applicationViewModal" @successful="applicationViewed"></application-modal>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import Jspdf from 'jspdf';
import 'jspdf-autotable';
import application from '@/api/application';
import Pagination from '@/components/layout/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

import ApplicationModal from '@/components/application/Modal/ApplicationFormModal.vue';

export default {
  name: 'application-list',
  mixins: [paginationMixin],
  components: {
    ApplicationModal,
    Pagination,
  },
  data() {
    return {
      loading: {
        application: false,
        status: false,
      },
      applicationList: [],
      page: null,
      pagination: null,
      searchName: '',
      searchStatus: null,
      zipCodeList: [],
      searchZipCode: null,
      statusList: [
        {
          id: 1,
          name: 'Hafnað',
        },
        {
          id: 2,
          name: 'Samþykkt',
        },
        {
          id: 3,
          name: 'Óafgreidd',
        },
      ],
    };
  },
  computed: {},
  methods: {
    async loadZipCodeList() {
      try {
        const response = await application.zipcodeList();
        this.zipCodeList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir stofnanir. Vinsamlegast reyndu aftur.`,
        );
      }
    },
    async loadList(page = null, limit = null) {
      this.loading.application = true;
      this.pagination = null;
      this.applicationList = [];
      try {
        const response = await application.applicationList(page, limit, this.searchName, this.searchStatus, this.searchZipCode);
        this.applicationList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir umsóknir. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading.application = false;
      }
    },
    applicationViewModal(id, status) {
      this.$refs.applicationViewModal.show(id, status);
    },
    applicationViewed() {
      this.loadList();
    },
    applicationPdf(app) {
      let status = '';
      const professionCol = [
        { title: 'Starfsgrein', dataKey: 'name' },
        { title: 'Fj. plássa', dataKey: 'slots' },
      ];
      // FYRIR VERKFLOKKA
      /* const factorCategoryCol = [
        { title: 'Verkflokkur', dataKey: 'name' }
      ]; */
      const supervisorCol = [
        { title: 'Tilsjónaraðili', dataKey: 'name' },
        { title: 'Kennitala', dataKey: 'ssn' },
        { title: 'Símanúmer', dataKey: 'mobile' },
      ];
      const doc = new Jspdf({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter',
      });
      const pdfName = `Umsókn nr_${app.application_id}_${app.name}`;
      const professionOptions = { startY: 5 };
      const supervisorOptions = { startY: 7 };

      doc.setFontSize(16).setFont(undefined, 'bold').text(`Umsókn nr. ${app.application_id}: ${app.name}`, 0.5, 1.0);
      doc.setLineWidth(0.03).line(0.5, 1.1, 8.0, 1.1);

      doc.setFontSize(12).setFont(undefined, 'normal').text('Fyrirtæki: ', 0.5, 2);
      doc.setFontSize(12).setFont(undefined, 'bold').text(app.name, 2, 2);

      doc.setFontSize(12).setFont(undefined, 'normal').text('Kennitala: ', 0.5, 2.5);
      doc.setFontSize(12).setFont(undefined, 'bold').text(app.ssn, 2, 2.5);

      doc.setFontSize(12).setFont(undefined, 'normal').text('Símanúmer: ', 0.5, 3);
      doc.setFontSize(12).setFont(undefined, 'bold').text(app.mobile.toString(), 2, 3);

      doc.setFontSize(12).setFont(undefined, 'normal').text('Netfang: ', 0.5, 3.5);
      doc.setFontSize(12).setFont(undefined, 'bold').text(app.email, 2, 3.5).setFont(undefined, 'bold');

      if (app.status === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        status = 'Í vinnslu';
      }
      if (app.status === 1) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        status = 'Hafnað';
      }
      if (app.status === 2) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        status = 'Samþykkt';
      }
      if (app.status === 3) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        status = 'Óafgreidd';
      }
      doc.setFontSize(12).setFont(undefined, 'normal').text('Staða umsóknar: ', 0.5, 4);
      doc.setFontSize(12).setFont(undefined, 'bold').text(status, 2, 4).setFont(undefined, 'bold');

      doc.autoTable(professionCol, app.professions, professionOptions);
      // doc.autoTable(factorCategoryCol, app.) // FYRIR VERKFLOKKA
      doc.autoTable(supervisorCol, app.supervisors, supervisorOptions);
      doc.save(pdfName || '.pdf');
    },
    ...mapActions(['displayError']),
  },
  watch: {
    searchName() {
      setTimeout(() => {
        this.loadList(null, null);
      }, 0);
    },
    searchStatus() {
      this.loadList(null, null);
    },
    searchZipCode() {
      this.loadList(null, null);
    },
  },
  created() {
    this.loadList();
    this.loadZipCodeList();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
