import Vue from 'vue';
import config from '../config';

export default {
  applicationList(page = null, limit = null, searchName = null, searchStatus = null, searchZipCode = null) {
    return Vue.http
      .get(config.APPLICATION_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
          searchStatus: searchStatus !== null ? searchStatus.id : null,
          searchZipCode: searchZipCode !== null ? searchZipCode.zipcode : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  myApplications(page, limit, meCompany, filterStatus, search = null) {
    return Vue.http
      .get(config.APPLICATION_LIST_URL, {
        params: {
          page,
          limit,
          meCompany,
          filterStatus,
          searchName: search,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  applicationDetail(id) {
    return Vue.http
      .get(config.APPLICATION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  applicationCreate(data) {
    return Vue.http
      .post(config.APPLICATION_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  applicationUpdate(id, data) {
    return Vue.http
      .put(config.APPLICATION_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  applicationEdit(id, data) {
    return Vue.http
      .post(config.APPLICATION_EDIT_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  applicationDelete(id) {
    return Vue.http
      .delete(config.APPLICATION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  applicationHandle(data) {
    return Vue.http
      .post(config.APPLICATION_HANDLE, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  checkSupervisor(ssn) {
    return Vue.http
      .get(config.SUPERVISOR_CHECK.replace('{ssn}', encodeURIComponent(ssn)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  supervisorList(data) {
    return Vue.http
      .post(config.SUPERVISOR_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  supervisorFileList(data) {
    return Vue.http
      .get(config.SUPERVISOR_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  professionList() {
    return Vue.http
      .get(config.PROFESSION_LIST_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  zipcodeList() {
    return Vue.http
      .get(config.ZIPCODE_LIST_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  roleList() {
    return Vue.http
      .get(config.ROLE_LIST_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  supervisorFileDownload(id, supervisorId, filename) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.APPLICATION_SUPERVISOR_FILE_DOWNLOAD_URL}?key=${id}&supervisor=${supervisorId}`,
    }).then((success) => {
      const a = document.createElement('a');
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      if (window.navigator.msSaveOrOpenBlob) {
        // Fyrir IE
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    });
  },
};
