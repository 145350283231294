<template>
  <div>
    <div>
      <b-row class="pt-3">
        <b-col>
          <div>
            <strong>{{ supervisor.name }}</strong>
          </div>
          <br />
          <div>
            <vue-dropzone
              id="supervisorfile"
              ref="myVueDropzone"
              name="supervisorfile"
              style="min-height: 50px; padding: 0px"
              @vdropzone-success="fileSuccess"
              @vdropzone-error="fileError"
              @vdropzone-sending="sendingFile"
              @vdropzone-file-added="saveFiles"
              @vdropzone-removed-file="onFileListUpdated"
              :options="dropzoneOptions"
            ></vue-dropzone>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import config from '@/config';
import auth from '@/api/auth';

export default {
  name: 'supervisor-data',
  props: ['supervisor', 'supervisorId', 'applicationId'],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      loading: false,
      dropzoneOptions: {
        url: config.APPLICATION_SUPERVISOR_FILE_URL,
        thumbnailWidth: 150,
        maxFilesize: 50,
        maxFiles: 1,
        supervisorId: 0,
        paramName: 'supervisorfile',
        autoProcessQueue: true,
        acceptedFiles: '.docx,.doc,.pdf,.txt,.xlsx,.xls,.png,.jpeg,.jpg',
        dictDefaultMessage: '<i class="fa fa-cloud-upload" style= "margin-top: -100px;"></i> Smelltu hér eða dragðu skrá inn á svæðið.',
        dictInvalidFileType: 'Skrá er ekki á leyfilegu skráarsniði.',
        dictMaxFilesExceeded: 'Hámarksfjölda skráa náð.',
        dictFileTooBig: 'Skrá er of stór. Hámarksstærð er 50MB.',
        dictRemoveFile: 'Fjarlægja skrá.',
        addRemoveLinks: true,
        headers: { token: auth.getToken(), applicationId: 0, supervisorId: 0, ssn: '' }, // bæta við supervisorId frá db
      },
    };
  },
  methods: {
    onFileListUpdated() {
      this.$emit('has-file', this.$refs.myVueDropzone.getAcceptedFiles().length > 0);
    },
    filesUploadedSuccess() {
      const supervisorFiles = {
        supervisorId: this.supervisorId,
        files: this.$refs.myVueDropzone.getAcceptedFiles(),
      };
      this.$emit('uploaded-files', supervisorFiles);
    },
    /*
      Skjöl
    */
    async saveFiles() {
      this.onFileListUpdated();
      try {
        const uploadPromise = new Promise((resolve, reject) => {
          this.$once('file-success', () => {
            resolve();
          });
          this.$once('file-error', (message) => {
            reject(message);
          });
        });

        await uploadPromise;
        this.filesUploadedSuccess();
        this.displaySuccess('Skjal vistað.');
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við vistun á skjali. Vinsamlegast reyndu aftur.`);
      }
    },
    fileSuccess() {
      this.onFileListUpdated();
      this.$emit('file-success');
    },
    fileError(file, message, xhr) {
      if (xhr) {
        this.$emit('file-error', {
          response: {
            data: message,
            status: xhr.status,
          },
        });
      } else if (message !== undefined) {
        this.displayError(`Ekki tókst að vista skjal. ${message}`);
      }
    },
    sendingFile(file, xhr, formData) {
      xhr.setRequestHeader('filename', file.name);
      xhr.setRequestHeader('contentType', file.type);
      formData.append('ssn', this.supervisor.ssn);
      formData.append('applicationId', this.applicationId);
      formData.append('supervisorId', this.supervisorId);
    },
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>
