<template>
  <div>
    <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
    <div>
      <div v-for="(factor, index) in factorList" :key="index">
        <b-row class="pb-2 pt-2 line-title" style="cursor: pointer" :class="{ light_gray: index % 2 === 0, white: index % 2 !== 0 }">
          <b-col cols="9" :id="`name${factor.id}`">
            <div class="pull-left d-inline" style="min-width: 20px">{{ index + 1 }}.</div>
            <div class="d-inline">
              <p
                class="d-inline"
                style="padding-right: 1px"
                v-b-popover.hover.html="getFactorDescription(factor)"
                title="Lýsing verkþáttar"
              >
                {{ factor.name }}
              </p>
              <p class="d-inline" v-b-popover.hover.html="getCompetence(factor.competence)" title="Hæfnikröfur">
                ({{ factor.competence.length }})
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

export default {
  name: 'careerbook-category-factor-list',
  components: {},
  props: ['id', 'category'],
  data() {
    return {
      loading: false,
      factorList: null,
    };
  },
  methods: {
    async getFactors() {
      this.loading = true;
      try {
        const response = await core.factorList(this.category, true);
        this.factorList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkþætti. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    getCompetence(competence) {
      let html = '<ul>';
      if (competence !== undefined) {
        competence.forEach((c) => {
          html += `<li>${c.name}</li>`;
        });
        html += '</ul>';
        if (competence.length === 0) {
          html = '<i>Engin tengd</i>';
        }
      } else {
        html = '<i>Engin tengd</i>';
      }

      html += '</ul>';
      return html;
    },
    getFactorDescription(factor) {
      let html;
      if (factor.factor_description !== null) {
        html = `<i>${factor.factor_description}</i>`;
      } else {
        html = `<i>Engin lýsing</i>`;
      }
      return html;
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.getFactors();
  },
};
</script>
