<template>
  <page-login>
    <b-row align-h="center" class="mt-5">
      <b-col cols="12" md="6" lg="6">
        <b-card no-body class="text-center">
          <div class="card-body">
            <h4 class="mb-3">Innskráning</h4>
            <p>Smelltu á takkann hér fyrir neðan til þess að skrá þig inn með Íslykli.</p>
            <span v-if="loading"><i class="fa fa-spin fa-spinner"></i></span>
            <a v-else :href="url" class="btn btn-lg btn-secondary btn-login"> Skrá inn með Íslykli </a>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </page-login>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '../api/auth';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      url: process.env.VUE_APP_ISLAND_URL,
      // warningList: [],
      // errorList: [],
      // infoList: [],
    };
  },
  methods: {
    // Innskráning í kerfið. Tekur við tokeni og staðfestingu frá island.is
    async login(token) {
      this.loading = true;

      try {
        const response = await auth.checkLogin(token);
        if (response.data.successful === true) {
          auth.setToken(response.data.token);
          auth.setTokenExpire(response.data.timeleft);

          this.$router.push({ name: 'Index' });
        }
      } catch (e) {
        this.$log.error(e);
        if (e && e.response && e.response.status === 404) {
          this.displayError('Ekki fannst aðgangur fyrir valda kennitölu.');
        } else if (e && e.response && e.response.status === 401) {
          this.displayError('Kennitala finnst ekki í fyrirtækjaskrá');
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading = false;
      }
    },
    // Tekið út meðan ekki er til column í töflunni System_status (sjá OneNote)
    /*
    async getSystemErrorStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLogin('error');
        this.errorList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getSystemWarningStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLogin('warning');
        this.warningList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getSystemInfoStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLogin('info');
        this.infoList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },
    */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if ('token' in this.$route.params) {
      this.login(this.$route.params.token);
    }
    // Tekið út meðan ekki er til column í töflunni System_status (sjá OneNote)
    // this.getSystemErrorStatus();
    // this.getSystemWarningStatus();
    // this.getSystemInfoStatus();
  },
};
</script>

<style lang="scss" scoped>
@import '../style/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.btn-login {
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.systemErrorMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(247, 95, 95);
}

.systemWarningMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(255, 255, 128);
}

.systemInfoMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: #acd4ff;
}
</style>
