import Vue from 'vue';
import { myPage as config } from '../config';

export default {
  getWorkplace() {
    return Vue.http
      .get(config.WORPLACE)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  editWorkplace(data) {
    return Vue.http
      .put(config.WORPLACE, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
