const API_URL = '/fbk/';

/**
 * LOGIN
 */
const LOGIN_TOKEN = 'id_token';
const LOGIN_TOKEN_EXPIRE = 'id_token_exp';
const PING_URL = `${API_URL}application/auth/ping`;
const CHECK_LOGIN_URL = `${API_URL}application/auth/check-login`;
const ME_URL = `${API_URL}application/auth/me`;
const USER_CHECK_URL = `${API_URL}application/auth/usercheck/{ssn}`;
const USER_LIST_URL = `${API_URL}application/auth/users`;
const USER_DETAIL_URL = `${API_URL}application/auth/users/{id}`;

const SHOW_SYSTEM_STATUS_LOGIN = `${API_URL}core/systemstatuslogin`;
const SHOW_SYSTEM_STATUS_LOGGED_IN = `${API_URL}core/systemstatusloggedin`;

/**
 * APPLICATION
 */
const APPLICATION_LIST_URL = `${API_URL}application/application`;
const APPLICATION_DETAIL_URL = `${API_URL}application/application/{id}`;
const APPLICATION_EDIT_URL = `${API_URL}application/application-edit/{id}`;
const PROFESSION_LIST_URL = `${API_URL}application/profession`;
const SUPERVISOR_LIST_URL = `${API_URL}application/supervisor`;
const SUPERVISOR_CHECK = `${API_URL}application/supervisor-check/{ssn}`;
const APPLICATION_HANDLE = `${API_URL}application/application/handle`;
const ZIPCODE_LIST_URL = `${API_URL}application/zipcode`;
const ROLE_LIST_URL = `${API_URL}application/supervisor/roles`;
const NATIONAL_REGISTRY_URL = `${API_URL}application/auth/national-registry/{nationalId}`;
const NATIONAL_REGISTRY_URL_FULL = `${API_URL}application/auth/national-registry-info/{nationalId}`;

/**
 * SKJÖL
 */
const APPLICATION_SUPERVISOR_FILE_URL = `${API_URL}application/supervisor-file`;
const APPLICATION_SUPERVISOR_FILE_DOWNLOAD_URL = `${API_URL}application/download-file`;

/**
 * CORE
 */
const FACTOR_CATEGORY_LIST_URL = `${API_URL}core/factorcategory`;
const FACTOR_LIST_URL = `${API_URL}core/factor`;

/**
 * LOGIN
 */
const PROFESSION_CATEGORY_VERSIONS_LIST = `${API_URL}application/core/professioncategoryversions`;
const COMPETENCE_CATEGORY_LIST_URL = `${API_URL}application/core/competencecategory`;
const WORKPLACE_LIST_URL = `${API_URL}application/core/workplace`;
const WORKPLACE_ZIPCODE_LIST_URL = `${API_URL}application/core/zipcodes`;
const WORKPLACE_PROFESSIONS_LIST_URL = `${API_URL}application/core/workplaceprofessions`;

/**
 * My page
 */

export const myPage = {
  WORPLACE: `${API_URL}application/my-page/workplace`,
};

/**
 * Misc
 */

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

/* eslint-enable */
export default {
  /**
   * LOGIN
   */
  LOGIN_TOKEN,
  LOGIN_TOKEN_EXPIRE,
  PING_URL,
  CHECK_LOGIN_URL,
  ME_URL,
  USER_CHECK_URL,
  USER_LIST_URL,
  USER_DETAIL_URL,

  SHOW_SYSTEM_STATUS_LOGIN,
  SHOW_SYSTEM_STATUS_LOGGED_IN,

  /**
   * APPLICATION
   */
  APPLICATION_LIST_URL,
  APPLICATION_DETAIL_URL,
  APPLICATION_EDIT_URL,
  PROFESSION_LIST_URL,
  SUPERVISOR_LIST_URL,
  SUPERVISOR_CHECK,
  APPLICATION_HANDLE,
  ZIPCODE_LIST_URL,
  ROLE_LIST_URL,
  NATIONAL_REGISTRY_URL,
  NATIONAL_REGISTRY_URL_FULL,

  /**
   * SKJÖL
   */
  APPLICATION_SUPERVISOR_FILE_URL,
  APPLICATION_SUPERVISOR_FILE_DOWNLOAD_URL,
  /**
   * CORE
   */
  FACTOR_CATEGORY_LIST_URL,
  FACTOR_LIST_URL,

  /**
   * LOGIN
   */
  PROFESSION_CATEGORY_VERSIONS_LIST,
  COMPETENCE_CATEGORY_LIST_URL,
  WORKPLACE_LIST_URL,
  WORKPLACE_ZIPCODE_LIST_URL,
  WORKPLACE_PROFESSIONS_LIST_URL,
};
