import auth from '@/api/auth';
import store from '@/store';

function multiguards(guards) {
  return async (to, from, next) => {
    // a flag to discard remaining guards
    let changed = false;

    // handle next for multiple guards
    // eslint-disable-next-line func-names
    const mNext = function (value) {
      // prevent calling next after it is already resolved with a value
      if (changed) return;

      // if we have 'value' reslove next with the value and set changed flag
      if (typeof value !== 'undefined') {
        changed = true;
        next(value);
      }
    };

    // call guards
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < guards.length; i++) {
      if (changed) break;
      // eslint-disable-next-line no-await-in-loop
      await guards[i](to, from, mNext);
    }

    // move on if none of guards resolved next with a value
    if (!changed) next();
  };
}

const checkExpired = (token, next) => {
  if (!token || auth.isTokenExpired()) {
    next({ name: 'Login' });
    return true;
  }

  return false;
};
const doRequireAuth = (to, from, next) => {
  const token = auth.getToken();

  if (checkExpired(token, next)) {
    return Promise.reject();
  }

  const q = [];

  if (store.state.loggedInUser == null) {
    q.push(store.dispatch('getLoggedInUser'));
  }

  return Promise.all(q)
    .then(() => {})
    .catch(() => {})
    .then(() => {
      next();
    });
};

const isAdmin = (to, from, next) => {
  if (!store.state.loggedInUser || store.state.loggedInUser.is_admin !== 1) {
    next({ path: '/' });
    return;
  }

  next();
};

export default {
  multiguards,
  isAdmin,
  requireAuth(to, from, next) {
    return doRequireAuth(to, from, next).catch(() => {
      // not logged in
    });
  },

  /**
   * Skrá út fyrst
   */
  logoutBefore(to, from, next) {
    auth.removeToken();
    store.dispatch('doClean');
    next();
  },
};
