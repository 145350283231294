<template>
  <page>
    <company></company>
  </page>
</template>

<script>
import Company from '@/components/index/Company.vue';

export default {
  name: 'Index',
  components: {
    Company,
  },
};
</script>

<style scoped></style>
