<template>
  <b-modal id="applicationForm" title="Umsókn" size="xl" lazy :no-close-on-backdrop="true" ref="viewModal" :hide-footer="true">
    <application-form ref="applicationForm" :id="id" @successful="successful"></application-form>
    <br />
    <br />
    <b-button
      type="button"
      class="btn btn-m btn-primary pull-right"
      variant="success"
      ref="viewModal"
      :disabled="status === 2"
      @click.prevent="handleApplication(true)"
    >
      Samþykkja
    </b-button>
    <b-button
      type="button"
      class="btn btn-m"
      variant="danger"
      :disabled="status === 2 || status === 1"
      @click.prevent="handleApplication(false)"
    >
      Hafna
    </b-button>
  </b-modal>
</template>

<script>
import ApplicationForm from '@/components/application/ApplicationForm.vue';

export default {
  name: 'application-modal',
  components: {
    ApplicationForm,
  },
  data() {
    return {
      id: null,
      status: null,
    };
  },
  methods: {
    show(id = null, status = null) {
      this.id = id;
      this.status = status;
      this.$refs.viewModal.show();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.viewModal.hide();
    },
    handleApplication(status) {
      this.$refs.applicationForm.validateBeforeSubmit(status);
    },
  },
  created() {},
};
</script>
