<template>
  <div>
    <b-row>
      <b-col>
        <h3 class="mb-4 text-dark font-weight-bold">Yfirlit yfir notendur</h3>
      </b-col>
      <b-col>
        <div class="my-4 pull-right" v-if="loggedInUser.is_admin === 1">
          <b-link class="btn btn-secondary pull-right" href @click.prevent="userCreateModal">
            <i class="fa fa-fw fa-plus-circle"></i>
            Skrá notanda
          </b-link>
        </div>
      </b-col>
    </b-row>
    <table class="table">
      <tr>
        <th style="padding: 10px 10px 10px 0px">
          <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir nafni eða kennitölu">
          </b-form-input>
        </th>
      </tr>
    </table>
    <!-- <table class="table">
      <tr>
        <th style="padding: 10px 10px 10px 0px">
          <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir nafni eða kennitölu">
          </b-form-input>
        </th>
      </tr>
    </table> -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Notandi</th>
          <th>Kennitala</th>
          <th>Símanúmer</th>
          <th>Netfang</th>
          <th>Virkur</th>
          <th>Admin</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="userList.length == 0">
          <td colspan="3">
            <i>Engar leitarniðurstöður fundust.</i>
          </td>
        </tr>
        <tr v-for="(user, index) in userList" :key="index">
          <td>
            <div class="d-inline-block">
              <span v-if="loggedInUser.is_admin === 1">
                <a href @click.prevent="userUpdateModal(user.id)">
                  {{ user.name }}
                  <i style="cursor: pointer"></i>
                </a>
              </span>
              <span v-else>
                {{ user.name }}
              </span>
            </div>
          </td>
          <td>{{ user.ssn }}</td>
          <td>{{ user.mobile }}</td>
          <td>{{ user.email }}</td>
          <td>
            <div v-if="user.is_active === 1">Já</div>
            <div v-else>Nei</div>
          </td>
          <td>
            <div v-if="user.is_admin === 1">Já</div>
            <div v-else>Nei</div>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
    <!-- Modal til að skrá notanda -->
    <user-crud-modal ref="userCreateModal" @successful="userUpdated"></user-crud-modal>
    <!-- Modal til að uppfæra notanda -->
    <user-crud-modal ref="userUpdateModal" @successful="userUpdated"></user-crud-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';
import UserCrudModal from '@/components/user/UserCrudModal.vue';
import Pagination from '@/components/layout/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'all-users-list',
  components: {
    UserCrudModal,
    Pagination,
  },
  mixins: [paginationMixin],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      userList: [],
      page: null,
      pagination: null,
      searchName: '',
    };
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.pagination = null;
      this.userList = [];
      try {
        const response = await auth.userList(page, limit, this.searchName);
        this.userList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir notendur. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    userCreateModal() {
      this.$refs.userCreateModal.show();
    },
    userUpdateModal(id) {
      this.$refs.userUpdateModal.show(id);
    },
    userCreated() {
      this.loadList();
    },
    userUpdated() {
      this.loadList();
    },
    ...mapActions(['displayError']),
  },
  watch: {
    searchName() {
      setTimeout(() => {
        this.loadList(null, null);
      }, 0);
    },
  },
  created() {
    this.loadList();
  },
};
</script>
