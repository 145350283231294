import Vue from 'vue';
import config from '../config';

export default {
  factorCategoryList(profession, version, skipAuth = false) {
    return Vue.http
      .get(config.FACTOR_CATEGORY_LIST_URL, {
        params: {
          profession: !version ? profession : null,
          version,
        },
        skipAuthorization: skipAuth,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  factorList(category, skipAuth = false) {
    return Vue.http
      .get(config.FACTOR_LIST_URL, {
        params: {
          category,
        },
        skipAuthorization: skipAuth,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  professionCategoryVersionsList() {
    return Vue.http
      .get(config.PROFESSION_CATEGORY_VERSIONS_LIST, { skipAuthorization: true })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  professionVersionListLogin(id) {
    return Vue.http
      .get(config.PROFESSION_VERSION_LIST_LOGIN_URL.replace('{id}', encodeURIComponent(id)), { skipAuthorization: true })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  competenceListLogin(profession, version) {
    return Vue.http
      .get(config.COMPETENCE_CATEGORY_LIST_URL, {
        params: {
          profession: !version ? profession : null,
          version,
        },
        skipAuthorization: true,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  workplaceList(page = null, limit = null, profession = null, name = null, zipCode = null) {
    return Vue.http
      .get(config.WORKPLACE_LIST_URL, {
        params: {
          page,
          limit,
          profession: profession.join('#;#'),
          name: name !== '' ? name : null,
          zipCode: zipCode.join('#;#'),
        },
        skipAuthorization: true,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  workplaceZipCodeList() {
    return Vue.http
      .get(config.WORKPLACE_ZIPCODE_LIST_URL, {
        skipAuthorization: true,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  workplaceProfessionsList() {
    return Vue.http
      .get(config.WORKPLACE_PROFESSIONS_LIST_URL, {
        skipAuthorization: true,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Fetch from national registry (þjóðskrá)
   * Returns name
   */
  fetchNationalRegistryName(nationalId) {
    return Vue.http
      .get(config.NATIONAL_REGISTRY_URL.replace('{nationalId}', encodeURIComponent(nationalId)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Fetch from national registry (þjóðskrá)
   * Returns name, address and postal code
   */
  fetchNationalRegistryInfo(nationalId) {
    return Vue.http
      .get(config.NATIONAL_REGISTRY_URL_FULL.replace('{nationalId}', encodeURIComponent(nationalId)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
