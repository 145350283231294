import auth from '@/api/auth';

/**
 * Vinna úr blaðsíðuupplýsingum frá vefþjón
 * @param {objects} response
 */
export const parsePagination = (pagination, data) => {
  // eslint-disable-line import/prefer-default-export, max-len
  const currentLimit = parseInt(pagination['x-current-limit'], 10);
  const currentPage = parseInt(pagination['x-current-page'], 10);
  const totalCount = parseInt(pagination['x-total-count'], 10);
  const totalPages = parseInt(pagination['x-total-pages'], 10);

  const showingFrom = currentLimit * (currentPage - 1) + 1;
  const showingTo = currentLimit * (currentPage - 1) + data.length;

  return {
    currentLimit,
    currentPage,
    totalCount,
    totalPages,
    showingFrom,
    showingTo,
  };
};

/**
 * Mixin fyrir blaðsíður og limit
 */
export const paginationMixin = {
  data() {
    return {};
  },
  methods: {
    changePage(page) {
      this.loadList(page, this.pagination.currentLimit);
    },
    changeLimit(limit) {
      this.loadList(null, limit);
    },
  },
};

export const fileDropzoneConfig = () => ({
  url: '/',
  maxFilesize: 500,
  maxFiles: 10,
  paramName: 'file',
  acceptedFiles: '.docx,.doc,.pdf,.xlsx,.xls',
  autoProcessQueue: false,
  addRemoveLinks: true,
  dictDefaultMessage: '<i class="fa fa-cloud-upload"></i> Smelltu hér eða dragðu skrá inn á svæðið.',
  dictFileTooBig: 'Skrá er of stór ({{filesize}} MB). Hámarksstærð er {{maxFilesize}} MB.',
  dictInvalidFileType: 'Skrá er ekki á leyfilegu skráarsniði.',
  dictRemoveFile: 'Fjarlægja skrá.',
  dictMaxFilesExceeded: 'Hámarksfjölda skráa náð.',
  headers: {
    token: auth.getToken(),
  },
});

export const imageDropzoneConfig = () => ({
  url: '/',
  maxFilesize: 5, // mb
  maxFiles: 5,
  paramName: 'image',
  acceptedFiles: '.jpg,.jpeg,.png',
  // createImageThumbnails: true,
  // thumbnailWidth: 250, // px
  autoProcessQueue: true,
  addRemoveLinks: true,
  // forceFallback: true,
  dictDefaultMessage: '<i class="fa fa-cloud-upload"></i> Smelltu hér eða dragðu skrá inn á svæðið.',
  dictFileTooBig: 'Skrá er of stór ({{filesize}} MB). Hámarksstærð er {{maxFilesize}} MB.',
  dictInvalidFileType: 'Skrá er ekki á leyfilegu skráarsniði.',
  dictRemoveFile: 'Fjarlægja skrá.',
  dictMaxFilesExceeded: 'Hámarksfjölda skráa náð.',
  headers: {
    token: auth.getToken(),
  },
});

export const randomId = () => {
  return new Date().getTime().toString(36) + Math.random().toString(36).slice(2);
};

export const masterRoleString = '1. Meistari';
export const journeymanRoleString = 'Sveinn';
export const monitorRoleString = 'Eftirlitsaðili';
export const extraMasterRoleString = 'Meistari';

export const roleMap = {
  [masterRoleString]: 1,
  [journeymanRoleString]: 2,
  [monitorRoleString]: 3,
  [extraMasterRoleString]: 4,
};

export function debounce(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}
